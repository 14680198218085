body {
    font-family: 'Montserrat Alternates', sans-serif !important;
    padding-top: 112px;
}

header.is-sticky {
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 1px 11px #d6d3d35e;
}

header.myheader {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    -webkit-box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 3px;
}

/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
::-webkit-scrollbar-corner {
    display: none;
    height: 0px;
    width: 0px;
}

/* Header css start Now */
header {
    min-height: 100px;
    background: #fffef5;
    vertical-align: middle;
    margin: auto;
    border-top: 8px solid #6d2a5f;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    transition: 0.1s;
}

.navbar-brand img {
    width: 250px;
}

.btn-primary {
    background: #6d2a5f !important;
    border: 1px solid #6d2a5f !important;
    border-radius: 55px;
    transition: 1s;
    padding: 10px 25px;
    font-weight: 600;
    white-space: nowrap;
    line-height: 18px;
}

.btn-primary:hover {
    color: #6d2a5f !important;
    box-shadow: inset 240px 0px 0px #fffef5;
}

.cartprofile-outer {
    margin: 15px 30px 0px 15px;
}


.cartprofile-outer a {
    font-size: 25px;
    margin: 0px 10px;
    color: #0000008c;
    position: relative;
}

.cart {
    position: relative;
    padding-right: 13px;
    margin-right: 15px;
}

span.count {
    position: absolute;
    width: auto;
    padding: 0px 4px;
    height: 20px;
    background: #435568;
    display: flex;
    align-items: center;
    justify-content: center;
    right: initial;
    left: 23px;
    top: 11px;
    border-radius: 0px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
}

.enter-profile img {
    width: 63px;
}

.enter-profile {
    position: relative;
    padding: 0px 0px 15px;
    z-index: 10;
    white-space: nowrap;
}

ul.profilelist {
    position: absolute;
    background: #fffef5;
    padding: 0px;
    list-style: none;
    box-shadow: 0px 0px 7px #ccc;
    border-radius: 5px;
    top: 50px;
    width: 240px;
    overflow: hidden;
    display: none;
}

ul.profilelist span {
    padding: 9px 15px;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

ul.profilelist li a {
    text-decoration: none;
    padding: 7px 15px;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin: 0px;
    border-top: 1px solid #ccc;
    transition: 1s;
}

ul.profilelist li a:hover {
    color: #fff;
    background: #6d2a5f;
}

.enter-profile:hover ul.profilelist {
    display: block;
}

.navbar-expand-lg .navbar-nav a {
    padding: 10px 25px !important;
    color: #435568;
    font-weight: 500;
    text-decoration: none;
}

.navbar-expand-lg .navbar-nav a.active,
.navbar-expand-lg .navbar-nav a:hover {
    color: #6d2a5f;
}

.dropdown-menu {
    width: 100%;
    padding: 0px;
    border-radius: 0px;
    border: 0px;
    background: #fffef5;
    transition: 0.5s;
}

.dropdownmain {
    display: flex;
    justify-content: space-between;
    max-width: 1320px;
    margin: 0 auto;
}

.shaftPart {
    width: 85%;
}

.submenu-outer,
.storePart ul.storeMenu {
    padding: 0px 15px 15px;
}

.submenuList {
    padding: 0;
    list-style: none;
    margin: 0px;
    display: flex;
}

.submenuList>li {
    width: 20%;
}

.submenuList>li a {
    text-decoration: none;
    margin: 10px 0px 0px;
    display: block;
    color: #435568 !important;
    font-weight: 600;
    text-transform: lowercase;
    font-size: 18px;
}

.storePart {
    width: 15%;
    padding-left: 0px;
}

.dropdownmain h4 {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    margin: 0px;
    border-bottom: 1px solid #eeeeee33;
    padding: 15px;
}

ul.meghaMenu {
    padding: 0px;
    list-style: none;
    margin: 0px;
    position: relative;
}

.navbar-expand-lg .navbar-nav ul.meghaMenu li a {
    font-size: 15px;
    margin: 0px;
    padding: 4px 5px !important;
    color: #cfcfcf;
    transition: 1s;
    font-weight: 500;
    text-transform: lowercase;
}

ul.meghaMenu li a:hover,
ul.storeMenu li a:hover {
    color: #fff;
}

ul.storeMenu {
    padding: 0px;
    margin: 10px 0 0 0;
    list-style: none;
}

.navbar-expand-lg .navbar-nav ul.storeMenu li a {
    text-decoration: none;
    padding: 4px 5px !important;
    border: 0px;
    font-size: 15px;
    display: block;
    color: #435568 !important;
    transition: 1s;
    font-weight: 500;
}

.navbar-expand-lg .navbar-nav ul.storeMenu li a:hover {
    color: #6d2a5f !important;
}

.navbar-expand-lg .navbar-nav ul.storeMenu li.gearheading a {
    font-weight: 600 !important;
    color: #6d2a5f !important;
    padding: 10px 5px !important;
    font-weight: 500;
    font-size: 18px;
}

/* .nav-item:hover .dropdown-menu {
    display: block !important;
} */

.cart img {
    width: 20px;
}

section.sliderPart {
    position: relative;
    overflow: hidden;
    z-index: 0;
}

section.sliderPart .react-multiple-carousel__arrow {
    background: transparent;
    transition: 1s;
}

section.sliderPart .react-multiple-carousel__arrow:hover {
    background: rgb(109 42 95);
}

section.sliderPart .react-multiple-carousel__arrow::before {
    color: rgb(109 42 95);
}

section.sliderPart .react-multiple-carousel__arrow:hover::before {
    color: #fff;
}

section.sliderPart .react-multi-carousel-dot-list {
    bottom: 30px;
}

section.sliderPart .react-multi-carousel-dot button {
    border: 1px solid #fff;
}

section.sliderPart .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: rgb(109 42 95);
    border-color: rgb(109 42 95);
}

.sliderPart .image-gallery-content .image-gallery-slide .image-gallery-image,
.sliderPart .image-gallery-slides,
.sliderPart .image-gallery-slide.center {
    height: initial;
    max-height: initial;
}

.sliderPart .image-gallery-swipe {
    height: initial;
    width: 100%;
    display: inline-block;
}

.sliderPart .container {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
}

.slider-content {
    padding: 35px 35px;
    width: 530px;
    margin-left: auto;
    text-align: left;
    position: relative;
}

.slider-content h1 {
    font-size: 40px;
    font-weight: 700;
    color: #435568;

}

.slider-content h1::first-letter {
    text-transform: capitalize;
}

.slider-content h4 {
    font-weight: bold;
    font-style: italic;
    color: #6d2a5f;
    margin: 15px 0px;
    font-size: 27px;
}

.slider-content p {
    font-size: 17px;
    font-weight: 500;
    color: #435568;
    line-height: 30px;
}

.slider-content .btn-primary {
    font-size: 17px;
    padding: 15px 25px;
    background: #fffef5 !important;
    border-color: #435568 !important;
    color: #435568;
}

.slider-content .btn-primary.btn-dark,
.shaftContent .dark.btn-primary,
button.ms-3.btn-dark.btn.btn-primary,
.facility-outer .d-flex a.btn.btn-primary {
    background: #435568 !important;
    border-color: #435568 !important;
    color: #fffef5;
}

.shaftContent .dark.btn-primary:hover,
button.ms-3.btn-dark.btn.btn-primary:hover,
.facility-outer .d-flex a.btn.btn-primary:hover {
    color: #435568 !important;
}

.slider-content .btn-primary.btn-dark:hover {
    background: transparent !important;
    color: #435568 !important;
    border-color: #435568 !important;
}

.shaftPlayer .img-fluid {
    max-width: 50%;
}

.videoTab .img-fluid {
    width: 100%;
    height: 310px;
}

.slider-content .btn-primary:hover {
    border-color: #6d2a5f !important;
    background-color: #6d2a5f !important;
    color: #6d2a5f !important;
}

.slider-content .d-flex {
    margin: 25px 0px 0;
}

/* .sliderPart .container::after {
    content: "";
    position: absolute;
    width: 5000px;
    top: 0px;
    bottom: 0px;
    background: rgb(255 254 245 / 77%);
    right: -4987px;
} */
.text-overlay {
    position: absolute;
    width: 5200px;
    top: 0px;
    bottom: 0px;
    background: rgb(255 254 245 / 93%);
    left: 0;
    clip-path: polygon(0 20px, 20px 0, 96% 0, 100% 0%, 100% 100%, 100% 100%, 25px 100%, 0% 96%, 0% 20px);
}

.sliderinnercontent {
    position: relative;
    z-index: 10;
}

.ourProduct {
    padding: 100px 0px;
    background-image: url(../src/img/top-right.png), url(../src/img/left-bottom.png);
    background-size: 40%;
    background-position: top right, bottom left;
    background-repeat: no-repeat;
    background-color: #e8e5e0;
    background-attachment: fixed;
}

.product-outer-main {
    clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 100% 98%, 98% 100%, 2% 100%, 0% 98%, 0% 2%);
    position: relative;
    padding: 1px;
    background: #6d2a5f;
    overflow: hidden;
}

.product-outer {
    clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 100% 98%, 98% 100%, 2% 100%, 0% 98%, 0% 2%);
    position: relative;
    overflow: hidden;
}

.product-outer a {
    background: #fffef5;
    display: block;
    text-align: center;
    padding: 5px 10px;
    text-decoration: none;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 22%);
}

.product-outer a h3 {
    color: #435568;
    font-weight: 700;
    font-size: 40px;
}

.product-outer a h3 span.d-block {
    color: #435568;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 35px;
}

sup {
    /* width: 14px; */
    height: 14px;
    /* border: 1px solid #435568; */
    display: inline-flex;
    top: -10px;
    left: 0px;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    /* border-radius: 50%; */
    line-height: 21px;
    text-transform: uppercase;
    padding-bottom: 0px;
    font-weight: 600;
}

.product-outer p {
    font-size: 30px;
    margin: 0px;
    color: #6d2a5f;
    font-weight: 500;
    min-height: initial;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.product-main-image img {
    width: 55%;
}

.video-outer {
    background-image: url(../src/img/videosBG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0px;
    background-attachment: fixed;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.video-headings {
    width: 93%;
    margin: 0 auto 50px;
    color: #fffef5;
}

.video-headings h2 {
    font-size: 40px;
    font-weight: 700;
}

.video-headings h2 .innerpages p {
    font-size: 40px;
    font-weight: 700;
    line-height: initial;
}


.video-headings h3 {
    font-weight: 700;
    font-style: italic;
    margin: 15px 0px;
    font-size: 27px;
}

.video-headings p {
    font-size: 19px;
    font-weight: 500;
    line-height: 30px;
}

.video-headings hr {
    margin: 50px 0px;
}

.videoTab {
    border: 3px solid #6e2a5f;
    position: relative;
    overflow: hidden;
    width: calc(100% - 90px);
    height: 310px;
    text-align: center;
    margin: 0 auto;
}

.videos-outer {
    position: absolute;
    width: 100%;
    top: 100%;
}

.video-content {
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: #fffef5;
    padding: 25px;
    margin: 0 auto;
    left: 50%;
    width: 100%;
}

.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 0px;
    border-color: #fffef5;
}

.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 0px;
    border-color: #fffef5;
}

.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before {
    color: #fffef5;
}

.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right::before {
    color: #fffef5;
}

.playBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fffef5;
}

.playBtn img {
    width: 60px;
    margin: 0px 10px;
}

.video-content h4 {
    font-size: 40px;
    margin-bottom: 15px;
    font-weight: 600;
}

.video-headings h2 sup {
    border-color: #fffef5;
}

.video-content img {
    cursor: pointer;
}

.product-selector {
    padding: 50px 0px 50px;
    overflow: hidden;
    background-image: url(../src/img/white-BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

.product-heading {
    text-align: center;
    margin-bottom: 50px;
}

.exclusiveUpdates .product-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.productInner {
    text-align: right;
    width: 80%;
    margin: 0 auto;
    position: relative;
    max-width: 260px;
}

/* .productInner:hover, .swiper-slide-active .productInner {
    border-color: #6d2a5f;
    background: #6d2a5f;
} */
/* button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 39%;
    border-radius: 0px;
    background: transparent;
    border: 2px solid #030304;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before {
    color: #030304;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {right: 39%;border-radius: 0px;background: transparent;border: 2px solid #030304;}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right::before {
    color: #030304;
} */
.productInner:hover a h5,
.swiper-slide-active .productInner a h5 {
    color: #fffef5;
}

.productInner:hover:after,
.productInner:hover:before,
.productInner:hover a:after,
.productInner:hover a:before,
.swiper-slide-active .productInner:after,
.swiper-slide-active .productInner:before,
.swiper-slide-active .productInner a:after,
.swiper-slide-active .productInner a:before {
    background: #6d2a5f;
}

.videos-outer iframe {
    width: 100%;
    height: 310px;
}

.productInner a {
    padding-bottom: 20px;
    display: block;
}

.productInner a h5 {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #435568;
    font-size: 36px;
}

.advancedShaft {
    padding: 50px 0px;
    background-position: bottom;
}

.advancedShaft {
    background-image: url(../src/img/white-BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.exclusiveUpdates {
    background-image: url(../src/img/Kinetixx-background.jpg);
    padding: 50px 0px;
    color: #fff;
    background-size: 100%;
    border-top: 1px solid #fffef5;
}

.product-heading h2 {
    font-size: 40px;
    font-weight: 700;
    color: #435568;
}

.product-heading h3 {
    font-weight: 700;
    font-style: italic;
    color: #6d2a5f;
    margin: 15px 0px;
    font-size: 27px;
}

.product-heading p {
    font-size: 19px;
    font-weight: 500;
    line-height: 30px;
    color: #435568;
}

.advancements .slider-content h1 {
    color: #6e2a5f;
}

.advancements .slider-content h3 {
    font-weight: 700;
    font-style: italic;
    color: #435568;
    margin: 15px 0px;
    font-size: 27px;
}

.advancements .slider-content .btn-primary.btn-dark {
    background: #6e2a5f !important;
    border-color: #6e2a5f !important;
}

.advancements .slider-content .btn-primary.btn-dark:hover {
    box-shadow: inset 229px 0px 0px #e7e5e0;
    color: #6e2a5f !important;
}

.exclusiveUpdates .product-heading h2 {
    color: #e7e5e0;
}

.exclusiveUpdates .product-heading p {
    color: #e7e5e0;
    font-size: 22px;
    margin: 10px 0px 20px;
}

.exclusiveUpdates .btn-primary,
.technology-tabs .d-flex a.btn.btn-primary {
    background: #e7e5e0 !important;
    color: #6d2a5f;
}

.exclusiveUpdates .btn-primary:hover,
.technology-tabs .d-flex a.btn.btn-primary:hover {
    box-shadow: inset 229px 0px 0px #762a5f;
    color: #fffef5 !important;
}

.shaftContent .dark.btn-primary,
.exclusiveUpdates .btn-primary {
    padding: 15px 35px;
    font-size: 21px;
}

.social-icon ul li img {
    min-width: 36px;
    max-width: 36px;
    margin: 0px 20px 0px 0px;
}

.social-icon ul {
    padding: 0px;
    list-style: none;
    display: flex;
    margin-bottom: 0px;
}

footer p {
    text-align: center;
    font-size: 16px;
    margin: 50px 0px 0px;
    padding: 10px 0px 10px;
}

footer {
    background: #fffef5;
    position: relative;
    z-index: 100;
    padding:50px 0px 0px;
}

footer p sup {
    width: 14px;
    height: 14px;
    border: 1px solid #435568;
    display: inline-flex;
    top: 0px;
    left: -1px;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    border-radius: 50%;
    line-height: 31px;
    padding-top: 0px;
}

.swiper-button-prev,
.swiper-button-next {
    width: 35px !important;
    height: 40px !important;
    border: 2px solid #17141d;
    opacity: 1 !important;
}

.swiper-button-prev {
    left: calc(50% - 230px) !important;
}

.swiper-button-next {
    right: calc(50% - 202px) !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 20px !important;
    color: #000;
    font-weight: bold;
}

.nav-item.dropdown {
    position: initial;
}

.classAdded.videos-outer {
    top: 0px;
    z-index: 10;
}

.pageheading .headingContent {
    color: #435568;
    padding: 70px 0;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.productInner img {
    min-height: 230px;
    object-fit: contain;
}

section.pageheading {
    position: relative;
}

.headingContent h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #435568;
}

.headingContent h4 {
    font-size: 27px;
    font-weight: bold;
    font-style: italic;
    margin: 15px 0px;
    color: #435568;
}

.headingContent .btn-primary {
    background: #fff !important;
    color: #435568;
    padding: 20px 35px;
    font-size: 18px;
    font-weight: 700;
    transition: 1s;
    border: 1px solid #fff !important;
}

.headingContent .btn-primary:hover {
    box-shadow: inset 240px 0px 0px 0px #6d2a5f !important;
    border-color: #6d2a5f !important;
    color: #fffef5 !important;
}

.productlist-outer,
.profileDatatop {
    padding: 50px 0px 20px;
    background-image: url(../src/img/top-right.png), url(../src/img/left-bottom.png);
    background-size: 40%;
    background-position: top right, bottom left;
    background-repeat: no-repeat;
    background-color: #e8e5e0;
    background-attachment: fixed;
    min-height: 440px;
}

.productlist-outer .form-group .form-control {
    border-radius: 0px;
    height: 50px;
    color: #435568;
}

.productlist-outer .form-group .form-control,
.NewsMedia .form-group .form-control {
    border-radius: 0px;
    height: 50px;
    box-shadow: inset 0px 0px 6px hsl(0deg 0% 0% / 22%) !important;
    background-color: #fffef6;
    border: 1px solid #dcdcd6;
    font-weight: 500;
    color: #445568;
}

.productlist-outer .form-group .form-control.form-select {
    background-size: 21px;
    color: #435568;
}

.sidebar-outer {
    background: #6d2a5f;
    display: block;
    padding: 1px;
    text-decoration: none;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 22%);
    clip-path: polygon(0 3%, 3% 0, 97% 0, 100% 3%, 100% 97%, 97% 100%, 3% 100%, 0% 97%, 0% 3%);
    position: relative;
}

.sidebar {
    background: #fffef5;
    display: block;
    padding: 0px;
    text-decoration: none;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 22%);
    clip-path: polygon(0 3%, 3% 0, 97% 0, 100% 3%, 100% 97%, 97% 100%, 3% 100%, 0% 97%, 0% 3%);
    position: relative;
}

.sidebar h3 {
    padding: 18px 15px;
    border-bottom: 1px solid #e7e5df;
    color: #435568;
    font-weight: 600;
    font-size: 35px;
    text-align: center;
    margin: 0px;
}

ul.categoryList {
    padding: 0px;
    list-style: none;
    margin: 0px 0 0px;
}

ul.categoryList li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e7e5df;
    color: #435568;
    text-decoration: none;
    transition: 1s;
}

ul.categoryList li.active a,
ul.categoryList li:hover a {
    color: #6d2a5f;
}

.productName {
    display: flex;
    align-items: center;
}

.productName img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 12px;
    transition: 1s;
}

ul.categoryList li a:hover .productName img {
    transform: scale(1.3);
}

.productName h4 {
    font-size: 22px;
    font-weight: 600;
    margin: 0px;
}

.categoryList a {
    text-decoration: none;
}

.productAll {
    color: #6e2a5f;
    transition: 1s;
}

.productAll :hover,
.productAll.active {
    background: #e7e5df;
}

.productAll h4 {
    margin: 0px;
    font-size: 22px;
    font-weight: 700;
}

span.counter {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    transition: 1s;
}

ul.categoryList li a:hover .counter {
    background: #6e2a5f;
    color: #fff;
}

ul.categoryList li a:hover {
    background: #e7e5df;
}

.itemList {
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0px -15px;
}

.itemList li {
    padding: 0px 15px;
    width: 33.33%;
    margin-bottom: 30px;
}

.mainOuter-list {
    background: #6d2a5f;
    display: block;
    padding: 1px;
    text-decoration: none;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 22%);
    clip-path: polygon(0 3%, 3% 0, 97% 0, 100% 3%, 100% 97%, 97% 100%, 3% 100%, 0% 97%, 0% 3%);
    position: relative;
    margin-bottom: 0px;
    transition: 1s;
    height: 100%;
}

.mainOuter {
    background: #fffef5;
    display: block;
    padding: 0px;
    text-decoration: none;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 22%);
    clip-path: polygon(0 3%, 3% 0, 97% 0, 100% 3%, 100% 97%, 97% 100%, 3% 100%, 0% 97%, 0% 3%);
    position: relative;
    height: 100%;
    transition: 1s;
}

.itemContentOuter {
    padding: 15px;
    text-align: center;
    transition: 1s;
    min-height: 290px;
}

.itemContentOuter h4 {
    color: #435568;
    text-decoration: none;
    font-size: 27px;
    font-weight: 600;
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.itemContentOuter h4 a {
    color: #435568;
    font-size: 27px;
    font-style: italic;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
}

.itemContentOuter p {
    color: #435568;
    font-size: 19px;
    font-weight: 500;
    margin: 12px 0px 41px;
}

.itemContentOuter h5 {
    color: #753866;
    font-size: 30px;
    font-weight: 700;
}

.itemContentOuter h5 sup {
    border-color: #753866;
}

.itemContentOuter>span {
    font-size: 20px;
    margin: 20px 0px 0px;
    display: block;
    color: #435568;
    font-weight: 500;
}

.itemContentOuter svg {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #753866;
    font-size: 32px;
    cursor: pointer;
}

.ItemImgOuter img {
    height: 200px;
    object-fit: contain;
    width: 100%;
    mix-blend-mode: multiply;
}

.light {
    display: none;
}

/* .react-multi-carousel-item--active .productInner:hover {
    background: #6d2a5f;
}

.react-multi-carousel-item--active .productInner:hover .dark {
    display: none;
}

.react-multi-carousel-item--active .productInner:hover .light {
    display: block;
} */
.video-outer li.react-multi-carousel-item.react-multi-carousel-item--active {
    left: 30px;
}

.video-outer li.react-multi-carousel-item.react-multi-carousel-item--active+li.react-multi-carousel-item.react-multi-carousel-item--active {
    left: -30px;
}

.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:hover,
.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:hover {
    background: #fffef5;
}

.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:hover:before,
.video-outer button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:hover:before {
    color: #6e2a5f;
}

.itemContentOuter h5 {
    position: relative;
    transition: 1s;
}

.itemContentOuter h5::after {
    position: absolute;
    content: "";
    top: -10px;
    bottom: -10px;
    background: #6d2a5f;
    left: -1000px;
    transition: 0.5s;
    width: 150%;
}

.itemContentOuter:hover h5 {
    color: #fff;
    z-index: 10;
}

.mainOuter:hover .itemContentOuter h5 span {
    color: #fff;
    position: relative;
    z-index: 1000;
}

.mainOuter:hover .itemContentOuter h5::after {
    left: -50px;
}

.mainOuter:hover .itemContentOuter h5 sup {
    border-color: #fff;
}

.product-selector p {
    font-size: 19px;
    font-weight: 500;
    line-height: 30px;
    color: #435568;
    text-align: center;
    margin: 0px 0px 0px;
}

.productdetails-right h1 a {
    font-size: 40px;
    font-weight: 700;
    color: #435568;
    text-decoration: none;
}

.productdetails-right {
    padding: 30px 0px 0px;
}

.productDetails {
    background: #fffef5;
    position: relative;
}

.productdetails-right h1 a span {
    color: #6d2a5f;
}

.productdetails-right h4 {
    font-weight: 500;
    font-style: italic;
    margin: 10px 0px 5px;
    font-size: 27px;
    color: #435568;
}

.superiour-right h4 {
    font-weight: bold;
    font-style: italic;
    color: #6d2a5f;
    margin: 15px 0px;
    font-size: 27px;
}

.productdetails-right p {
    color: #435568;
}

.paymentoption {
    display: flex;
    align-items: center;
}

.paymentoption h5 {
    font-size: 40px;
    font-weight: 600;
    color: #6d2a5f;
    margin: 0px;
}

.quantity-tab {
    margin: 0px 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-tab .btn {
    border: 2px solid #333332 !important;
    border-radius: 0px;
    padding: 5px 10px !important;
    font-size: 16px;
}

.quantity-tab span {
    width: 40px;
    text-align: center;
}

.quantity-tab .btn:hover {
    background: #6d2a5f;
    border-color: #6d2a5f !important;
    color: #fff;
}

.alltypes {
    margin: 15px 0px;
}

.alltypes p {
    margin-bottom: 1px;
    font-size: 16px;
}

.alltypes p span {
    font-weight: 600;
    margin-right: 10px;
    display: inline-block;
}

.favrateicon svg {
    font-size: 30px;
    color: #6d2a5f;
    cursor: pointer;
}

.favrateicon hr {
    background: #c8c8c5;
    height: 2px;
    border: 0px;
}

.detailheading {
    font-size: 27px;
    color: #435568;
    font-weight: 600;
}

.selectproductcolor .btn label,
.selectproductflex .btn.block label {
    border-radius: 55px;
    transition: 1s;
    padding: 10px 25px;
    font-weight: 600;
    line-height: 18px;
    color: #fff !important;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.selectproductcolor .btn,
.selectproductflex .btn {
    padding: 0px;
    border: 0px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.selectproductcolor .btn input,
.selectproductflex .btn input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    cursor: pointer;
}

.selectproductcolor .btn.block label {
    background: #000;
    border: 1px solid #000;
}

.selectproductcolor .btn.Green label {
    background: #345921;
    border: 1px solid #345921;
}

.selectproductcolor .btn.Midnight label {
    background: #004a51;
    border: 1px solid #004a51;
}

.selectproductcolor .btn.Sky label {
    background: #00afdb;
    border: 1px solid #00afdb;
}

.selectproductflex .btn.block label {
    background: #e8e7e1;
    border: 1px solid #e8e7e1;
    color: #435568 !important;
}

.selectproductcolor .btn input:checked+label,
.selectproductflex .btn.block input:checked+label {
    background: #6d2a5f !important;
    border-color: #6d2a5f !important;
    color: #fff !important;
}

.quantity-tab span .form-control {
    background: transparent;
    text-align: center;
    pointer-events: none;
}

.custom_check {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 22px;
    position: relative;
    padding-left: 26px;
    margin: 5px 0px;
    font-weight: 600;
    font-size: 16px;
    color: #435568;
    z-index: 10;
}

.custom_check input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin: 0px 0px;
    z-index: 2;
}

.check_indicator {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #fffef8;
    border: 1px solid #435568;
    border-radius: 0px;
    box-shadow: 2px -1px 0px #435568;
}

.custom_check input:checked+.check_indicator:before {
    width: 5px;
    height: 10px;
    position: absolute;
    left: 6px;
    top: 2px;
    border: solid #435568;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    content: "";
}

.Quantitybox {
    margin: 0px;
}

.Quantitybox h5 {
    font-size: 27px;
    color: #6d2a5f;
    font-weight: 600;
}

.Quantitybox>div {
    display: flex;
    margin: 10px 0px 0px;
}

.Quantitybox div .quantity-tab {
    margin-left: 0px;
}

.quantity-tab .btn.tooltip.active svg {
    position: relative;
    top: -1px;
}
.modal-backdrop {
    z-index: 1000 !important;
}
.modal-content{
    z-index: 10000;
}
.modal {
    z-index: 10000 !important;
}
.checkouter {
    margin: 20px 0px 0px;
}
.coupancodeouter small.invalid-feedback-text.form-text {
    position: initial;
    white-space: pre-line;
}
.notify .btn-primary {
    padding: 10px 55px;
}

.superiordown {
    width: 530px;
    display: flex;
    position: absolute;
    bottom: calc(50% - 25%);
    align-items: center;
}

.superiour-left {
    min-width: 210px;
    max-width: 220px;
}

.superiour-right {
    padding-left: 15px;
}

.superiour-right h1 {
    font-size: 40px;
    font-weight: 700;
    color: #435568;
    text-decoration: none;
}

ul.bottomtabs {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 30px 0px 0px;
}

ul.bottomtabs li {
    width: calc(100% / 4 - 15px);
}

ul.bottomtabs li a {
    text-align: center;
    display: block;
    text-decoration: none;
}

.bottomtabs .nav-tabs {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 1px solid #6d2a5f;
}


.bottomtabs .nav-tabs li.nav-item button {
    border-radius: 10px 10px 0px 0px;
    padding: 7px 40px;
    background: transparent;
    color: #435568;
    font-weight: 600;
    font-size: 16px;
    border: 0px;
}

.bottomtabs .nav-tabs li.nav-item button.active {
    color: #fffef5;
    background: #6d2a5f;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 80px;
    width: 30px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
    border-color: #6d2a5f;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
    width: 100%;
}

.image-gallery-icon:hover {
    color: #6d2a5f;
}

.detailstabspanel {
    position: relative;
    background: #fffef5;
    padding-bottom: 50px;
    padding-top: 110px;
}

button.image-gallery-icon.image-gallery-play-button,
button.image-gallery-icon.image-gallery-fullscreen-button {
    display: none;
}

ul.reviewlist {
    list-style: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc;
}

ul.reviewlist li {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px 15px;
}

ul.reviewlist li:last-child {
    border: 0px;
}

.reviewouter {
    display: flex;
}

.reviewleft {
    display: flex;
    width: calc(100% - 115px);
}

span.userimg {
    width: 50px;
    height: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #435568;
    border-radius: 55%;
    text-transform: uppercase;
    color: #fff;
    font-family: sans-serif;
    font-size: 27px;
}

.usercomment {
    padding-left: 15px;
}

.usercomment h5 {
    font-weight: 600;
    color: #6d2a5f;
    margin-bottom: 0px;
}

.usercomment h5 span {
    display: block;
    font-size: 15px;
    font-weight: normal;
    color: #435568;
    margin: 5px 0px;
}

ul.reviewlist li p {
    font-size: 15px;
    margin: 8px 3px;
    word-break: break-all;
}

.reviewdate {
    font-size: 14px;
}

.starrating svg {
    /* color: #6d2a5f; */
    font-size: 20px;
}

.box-outer {
    cursor: pointer;
    padding: 60px 10px;
    margin-bottom: 0px;
}

.selectbrand-outer {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 10px;
}

.Cricle-outer {
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 50%;
    color: #ccc;
    display: flex;
    height: 125px;
    justify-content: center;
    margin: 10px auto;
    width: 125px;
}

.select-itembox {
    border: 1px solid #ccc;
    border-radius: 3px;
    overflow: hidden;
}

.customSelect .form-select {
    border-radius: 0px;
    border: 0px;
    height: 42px;
    box-shadow: none;
}

.checkoutpage,
.informations {
    background-image: url(../src/img/white-BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0px;
    position: relative;
    background-position: center;
    z-index: 0;
}

.checkoutpage::before,
.informations::before {
    content: "";
    position: absolute;
    background: #fffef5;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.5;
}

.checkoutpage .container,
.informations .container {
    position: relative;
    z-index: 100;
}

.checkoutproductdetails {
    background: #fffef5;
    padding: 0px;
    position: relative;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.checkoutDouter {
    display: flex;
    justify-content: space-between;
}

.checkoutleft {
    display: flex;
    align-items: flex-start;
    padding: 15px;
}

.additionalProduct {
    padding: 15px;
}

.checkimg {
    width: 170px;
    border: 1px solid #6d2a5f;
}

.checkoutDetail {
    padding-left: 15px;
}

a.removeproduct {
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-decoration: none;
    color: #6d2a5f;
    z-index: 10;
    cursor: pointer;
    font-weight: 600;
}

a.removeproduct svg {
    position: relative;
    top: -2px;
}

.totals {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 600;
}

.checkoutDetail a {
    text-decoration: none;
    color: #6d2a5f;
}

.checkoutDetail a h5 {
    font-weight: 700;
    font-size: 30px;
}

.checkoutDetail p {
    margin-bottom: 0px;
    font-size: 16px;
}

.checkoutDetail p span {
    font-weight: 600;
    color: #435568;
}

.totals span {
    font-weight: normal;
}

.checkoutDouter {
    position: relative;
}

.checkoutproductdetails hr {
    background: #c8c8c5;
    height: 2px;
    border: 0px;
    margin: 0px;
}

.additionalProduct h4 {
    font-size: 22px;
    font-weight: 600;
    color: #435568;
}

.additinalOuter {
    display: flex;
    flex-direction: column;
}

.additinalone {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

}

.addproductimg {
    min-height: 100px;
}

.addproductimg img {
    width: 100%;
}

.addproductimg {
    width: 170px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #6d2a5f;
}

.addcontent p {
    font-size: 14px;
    margin-bottom: 5px;
    word-wrap: break-word;
}

.addcontent {
    padding: 0px 0px 0px 15px;
    width: calc(100% - 170px);
    position: relative;
}

.addcontent p span {
    pointer-events: none;
    color: #828282cc;
    display: block;
}

.checkoutRight {
    background-color: #fffef5;
    padding: 25px 25px 0px;
    min-height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.coupancodeouter .form-control {
    border-radius: 3px;
    min-height: 50px;
    border: 1px solid #ccc;
    background: transparent;
    box-shadow: none !important;
    color: #435568;
}

.coupancodeouter {
    margin-bottom: 30px;
}

.coupancodeouter h4 {
    font-size: 22px;
    color: #435568;
    font-weight: 600;
}

.paymentsummry p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p.grandtotal {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-weight: bold;
    padding: 10px 0px 10px 0px;
}

a.continuebtn {
    text-decoration: none;
    color: #6d2a5f;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    display: inline-block;
}

.checkoutproductdetails:last-child {
    margin-bottom: 0px;
}

.checkoutpage h2 {
    font-weight: bold;
    margin-bottom: 20px;
    color: #6d2a5f;
}

p.totalpayment {
    position: absolute;
    top: 0px;
    right: 0px;
}


.selectproductcolor .form-group .form-select,
.selectproductcolor .form-group .form-control {
    background-color: transparent;
    border: 1px solid #e8e7e1;
    border-radius: 3px;
    box-shadow: none;
    color: #435568;
}

.selectproductcolor .form-group label,
.coupancodeouter label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    color: #435568;
}

.selectproductcolor .form-group {
    margin-bottom: 25px;
}

.adaptorImages {
    border: 1px solid #e8e7e1;
    background: #fff;
    text-align: center;
    margin-bottom: 25px;
    border-radius: 3px;
    margin-top: 26px;
    height: 146px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

textarea.form-control {
    min-height: 143px !important;
    color: #435568;
}

.custom_check a {
    color: #6d2a5f;
    font-size: 14px;
    position: relative;
    z-index: 100;
}

.kinetixxproductOuter a {
    outline: none;
    box-shadow: none;
    text-decoration: none;
}

.kinetixxproductOuter a h4 {
    text-align: center;
    color: #445568;
    position: relative;
    top: 20px;
    font-weight: 700;
    width: 85%;
    transition: .5s;
    font-size: 20px;
}

.slick-slide.slick-active.slick-center.slick-current .kinetixxproductOuter h4 {
    width: 80%;
    top: 10px !important;
    font-size: 27px !important;
}

.kinetixxproductOuter:hover a h4 {
    top: 40px;
    font-size: 27px;
}

.adaptorImages img {
    max-width: 80%;
    padding: 10px;
}

.custom_check span {
    display: block;
    font-size: 12px;
    color: #a2a2a2;
    line-height: 19px;
}

.selectproductcolor .form-group .form-select:disabled {
    background: #e8e7e1;
}

.coupancodeouter .ordersummery {
    color: #435568;
}

.coupancodeouter .ordersummery a {
    color: #6d2a5f;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
}

button.paypall {
    width: 100%;
    margin: 10px 0px;
    background: #FFC439 !important;
    border-color: #FFC439 !important;
}

button.paypall img {
    width: 58px;
}

button.paypall:hover {
    box-shadow: none !important;
}

button.debitcredit {
    width: 100%;
    background: #435568 !important;
    border-color: #435568 !important;
}

button.debitcredit:hover {
    box-shadow: none !important;
    color: #fff !important;
}

.informations h2 {
    font-weight: bold;
    color: #6d2a5f;
}

.breadcrumbOuter ol li {
    color: #435568 !important;
    font-weight: 600;
    font-size: 14px;
}

.informations h2 {
    font-weight: bold;
    color: #6d2a5f;
}

.breadcrumbOuter ol li a {
    color: #6d2a5f;
    text-decoration: none;
    font-weight: 500;
}

.contacttop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.Contact-informations {
    margin-bottom: 30px;
}

.contacttop h5,
.addressHeading h5 {
    color: #435568;
    font-weight: 600;
    margin-bottom: 0px;
}

.contacttop p {
    margin: 0px;
    font-size: 16px;
    color: #435568;
}

.contacttop p a {
    color: #6d2a5f;
    text-decoration: underline;
}

.form-group .form-control,
.form-group .form-select {
    color: #435568;
    min-height: 45px;
    border: 1px solid #ccc;
    background-color: transparent;
    box-shadow: none !important;
    border-radius: 3px;
    margin: 0px;
}

.form-group {
    margin-bottom: 25px;
}

.form-group .form-control.error {
    border-color: red;
}

.returnshopping {
    text-decoration: none;
    color: #435568;
}

.returnshopping svg {
    position: relative;
    top: -2px;
}

.returnshopping:hover {
    color: #6d2a5f;
}

.whiteBox {
    background: #fffef5;
    padding: 25px;
    border: 1px solid #ccc;
}

.whiteBox.mb-3 {
    min-height: 355px;
}

.paymentsidebar {
    background: #fffef5;
    border: 1px solid #ccc;
    padding: 25px;
    margin-top: 64px;
    min-height: calc(100% - 70px);
    border-radius: 5px;
}

.col-md-12+.col-lg-8.col-md-12+.col-lg-4.col-md-12 .paymentsidebar {
    margin-top: 30px;
}

ul.cartlist {
    padding: 0px;
    list-style: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

ul.cartlist:last-child {
    border: 0px;
}

ul.cartlist>li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    position: relative;
}

a.cart-delete-icon {
    position: absolute;
    right: 0px;
    bottom: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #6d2a5f;
}

.cart-partleft {
    display: flex;
    align-items: center;
    width: calc(100% - 110px);
}

.cartproductImg {
    width: 60px;
    min-width: 60px;
    height: 60px;
    background: #fff;
    padding: 2px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

ul.cat-additional-pModel .cart-partright {
    min-width: 90px;
}

span.productcount {
    position: absolute;
    width: 17px;
    height: 17px;
    background: #6d2a5f;
    color: #fff;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    top: -10px;
    right: -10px;
}

.cartproduct-content {
    padding-left: 15px;
}

.cartproduct-content h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #435568;
}

.cartproduct-content span {
    font-size: 14px;
    color: #435568;
}

.cart-partright {
    font-weight: 500;
    font-size: 14px;
    min-width: 110px;
    text-align: right;
}

ul.cartlist li:last-child {
    border: 0px;
    margin-bottom: 0px;
}

.giftcard {
    border: 1px solid #ccc;
    display: flex;
    overflow: hidden;
    border-radius: 55px;
}

.giftcard .form-control {
    background: transparent;
    border: 0px;
    box-shadow: none;
    font-size: 14px;
    color: #435568;
}

ul.infolist {
    border: 1px solid #ccc;
    background: #fffef5;
    padding: 25px;
    list-style: none;
}

ul.infolist li {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

ul.infolist li h5 {
    width: 110px;
    font-size: 16px;
}

ul.infolist li p {
    font-size: 14px;
    width: calc(100% - 150px);
    word-break: break-all;
}

ul.infolist li a {
    font-size: 14px;
    text-decoration: underline;
    color: #6d2a5f;
}

ul.infolist li:last-child {
    border: 0px;
    margin-bottom: 0px;
}

ul.infolist li:last-child p {
    margin-bottom: 0px;
}

ul.addressList {
    list-style: none;
    padding: 0px 0 0px;
    margin: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fffef5;
    max-height: 500px;
    overflow: auto;
}

.Contact-informations {
    position: relative;
}

li.add-new-address {
    background: #fffef5;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #ccc;
    border-top: 0px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

ul.addressList.address-fixed {
    max-height: 500px;
    overflow: auto;
    padding-bottom: 0px;
}

ul.addressList li {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}

ul.addressList li:last-child {
    border: 0;
}

ul.addressList li label {
    width: calc(100% - 34px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.fulladdress span {
    display: block;
    font-size: 12px;
}

span.fulladdress {
    font-weight: 500;
    color: #435568;
}

ul.addressList li input {
    width: 20px;
    height: 20px;
}

.contacttop p {
    font-size: 14px;
    margin: 5px 0px;
}

.creditcardform {
    padding: 15px 15px 0px;
    background: #fffef5;
    margin: 40px 0px 0px;
    position: relative;
    border-radius: 5px;
}

.creditcardform .form-group {
    position: relative;
}

.creditcardform .form-group svg {
    position: absolute;
    top: 14px;
    right: 18px;
    font-size: 21px;
    color: #435568;
}

ul.addressList li label span img {
    width: 145px;
    filter: inherit;
}

.DetailsSlider {
    position: absolute;
    left: 15px;
    width: calc(50% - 30px);
    bottom: 0%;
    transform: translateY(-50%);
}

.productDetails .container {
    position: relative;
}

.DetailsSlider .itemList li {
    width: calc(100% - 15px);
}

.DetailsSlider .mainOuter {
    margin-bottom: 70px;
}

.DetailsSlider button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    bottom: 0px;
    left: calc(50% - 58px);
}

.DetailsSlider button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    bottom: 0px;
    right: calc(50% - 42px);
}

.orOuter {
    position: relative;
}

span.orcontent {
    position: absolute;
    right: -40px;
    top: 79px;
    transform: translateY(-50%);
    font-weight: bold;
    color: #435568;
    font-size: 15px;
    background: #fffef5;
    padding: 10px 15px 11px;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: none;
}

.DetailsSlider h3 {
    margin-bottom: 13px;
    font-weight: bold;
    color: #435568;
}

a.Links {
    color: #6d2a5f;
}

li.react-multi-carousel-item.react-multi-carousel-item--active:last-child .productInner img {
    width: 80px;
}

.kinetixxproductOuter {
    width: 70%;
    margin: 0 auto;
    padding: 40px 0px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px;
    opacity: 1;
    color: #6d2a5f;
    display: none;
}

.slick-slide {
    opacity: 0;
}

.slick-slide.slick-active {
    opacity: 1;
}

.slick-slide.slick-active.slick-center.slick-current .kinetixxproductOuter {
    width: 100% !important;
}

.slick-slide.slick-active.slick-center.slick-current .kinetixxproductOuter img {
    width: 100% !important;
    top: 50px;
    margin-bottom: 35px !important;
}

.slick-slide.slick-active+.slick-slide.slick-active,
.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active {
    position: relative;
    top: 44px;
}

.slick-slide.slick-active+.slick-slide.slick-active .kinetixxproductOuter,
.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active .kinetixxproductOuter {
    width: 100%;
    padding-bottom: 30px;
}

.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active .kinetixxproductOuter {
    width: 70%;
}

.slick-slide.slick-active+.slick-slide.slick-active .kinetixxproductOuter img,
.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active .kinetixxproductOuter img {
    width: 75%;
    margin: 0 auto;
}

.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active+.slick-slide.slick-active {
    top: 0px;
}

.slick-slide.slick-active.slick-center.slick-current {
    top: 0px !important;
}

label {
    font-weight: 600;
    color: #435568;
}

input::-webkit-input-placeholder {
    /* Edge */
    color: #435568;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #435568;
}

input::placeholder {
    color: #435568;
}

.kinetixxproductOuter img {
    transition: transform .5s;
}

.kinetixxproductOuter:hover img {
    transform: scale(1.5);
}

.slick-center.slick-current .kinetixxproductOuter:hover img {
    transform: scale(1);
}

.product-selector .product-heading h2 {
    color: #6d2a5f;
}

.product-selector .product-heading h3 {
    color: #445569;
}

.product-heading img {
    width: 300px;
    transition: 1s;
}

.kinetixxproductOuter {
    position: relative;
}

.kinetixxproductOuter .darkimg {
    position: absolute;
    max-width: 100%;
    top: 30px;
    opacity: 0;
    left: 0px;
    right: 0px;
}

.slick-current .kinetixxproductOuter .lightimg {
    opacity: 0;
}

.slick-current .kinetixxproductOuter .darkimg {
    opacity: 1;
}

.kinetixxproductOuter:hover .lightimg {
    opacity: 0;
}

.kinetixxproductOuter:hover .darkimg {
    opacity: 1;
    transition: 1s;
}

/* .compareOuter {
    border: 0px;
    background-image: url(../src/img/compare-bg.png);
    background-size: 100%;
    background-repeat: repeat;
    padding-bottom: 40px;
} */
.compareproduct-mainouter {
    background-image: url(../src/img/white-bg-left.png),
        url(../src/img/white-bg-right.png) !important;
    background-size: 30%;
    background-position: bottom left, top right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #fffef9;
}

.compareOuter .box-outer {
    border: 0px;
    background: transparent;
}

.compareOuter h2 {
    color: #fffdf5;
    font-weight: 600;
    font-size: 40px;
}

.compareOuter .headcenter h2 {
    color: #fffdf5;
    text-shadow: 0px 0px 8px #435568;
    font-size: 40px;
    font-weight: 700;
}

.headcenter {
    text-align: center;
    padding: 0px 0px 0px;
    position: relative;
}

.compareOuter h4 {
    font-weight: bold;
    font-style: italic;
    color: #435568;
    margin: 15px 0px 0px;
    font-size: 27px;
}

.compareOuter .selectbrand-outer {
    background: #fffdf5;
}

.customSelect .form-select:disabled {
    background-color: #6c2a5f;
    color: #fff;
    background-image: url(../src/img/downArrow.png);
    background-size: 13px;
}

.customSelect .form-select {
    background-color: #fffdf5;
}

.headcenter img {
    width: 100%;
    object-fit: cover;
}

.headcenter div {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

.navbar-expand-lg .navbar-nav .dropdownmain h4 a {
    text-decoration: none;
    color: #fff;
}

ul.meghaMenu li a::first-letter {
    text-transform: capitalize;
}

.submenuList>li a::first-letter {
    text-transform: capitalize;
}

.productName h4 {
    font-size: 22px;
    font-weight: 700;
}

.productName h4::first-letter {
    text-transform: capitalize;
}

ul.categoryList li a:hover .counter,
ul.categoryList li.active a .counter {
    background: #6e2a5f;
    color: #fff;
}

ul.categoryList li a:hover,
ul.categoryList li.active a {
    background: #e7e5df;
}

.itemContentOuter h5 a {
    color: #753866;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.mainOuter:hover .itemContentOuter h5 span a {
    color: #fff;
}

section.orderList {
    background: #fffef5;
    padding: 40px 0px;
}

.orderOuter {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: .5s;
}

.orderOuter h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    color: #6d2a5f;
    font-size: 22px;
}

.orderOuter h3 a {
    color: #6d2a5f;
    text-decoration: none;
}

.orderOuter p {
    color: #435568;
    font-weight: 500;
    margin-bottom: 0px;
}

.orderOuter.pending h3 svg {
    color: red;
}

.orderOuter:hover {
    background: #6d2a5f;
}

.orderOuter.pending:hover h3 svg {
    color: #fff;
}

.orderOuter:hover h3 {
    color: #fff;
}

.orderOuter:hover h3 a,
.orderOuter:hover p,
.orderOuter:hover p a {
    color: #fff;
}

.orderOuter p a {
    color: #6d2a5f;
    text-decoration: none;
}

.PrfoleBanner {
    background-image: url(../src/img/profileBanner.jpg);
    background-position: center;
    background-attachment: fixed;
    background-size: 100%;
    padding: 100px 0px;
}

.pagesheading h2 {
    text-align: center;
    color: #fffef5;
    font-weight: bold;
    font-size: 70px;
}

/* .profileDatatop {
    padding: 50px 0px 20px;
    background-image: url(../src/img/white-BG.jpg);
    background-size: cover;
    background-repeat: no-repeat;
} */

.infotype .accordion {
    border: 0px;
    background: transparent;
    border-radius: 0px;
}

.infotype .accordion .accordion-item {
    border: 0px !important;
    border-radius: 0px;
    margin-bottom: 1px;
    background: transparent;
}

.infotype .accordion-header {
    border: 0px;
}

.infotype .accordion-button {
    border: 0px !important;
    background-color: #435568;
    border-radius: 0px !important;
    color: #fff;
    box-shadow: none !important;

}

.accordion-button::after {
    background-image: url(../src/img/downArrow.png) !important;
    background-size: 15px;
    background-position: right;
}

.infotype .accordion-button[aria-expanded="true"] {
    background-color: #6d2a5f;
}

ul.tablistdatalist {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

ul.tablistdatalist li a {
    background: #435568;
    display: block;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;
    margin-bottom: 1px;
}

.infotype .accordion-body {
    padding: 15px;
}

ul.tablistdatalist li a.active {
    background: #6d2a5f;
}

.infotype {
    margin-bottom: 290px;
}

.profileDatatop .sidebar h3 {
    font-size: 27px;
}

.profileDatatop .sidebar::before {
    top: 8px;
    left: -12px;
    transform: rotate(-58deg);
}

.profileDatatop .sidebar::after {
    top: 10px;
    right: -13px;
    transform: rotate(58deg);
}

.profileDatatop .sidebar h3::before {
    bottom: 5px;
    left: -10px;
    transform: rotate(57deg);
}

.profileDatatop .sidebar h3::after {
    bottom: 1px;
    right: -8px;
    transform: rotate(-57deg);
}

.maininfoOuter {
    background: #fffef5;
    padding: 25px;
    min-height: 100%;
    border-radius: 25px 0px 0px 25px;
}

.infoheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #435568;
    padding-bottom: 15px;
    margin-bottom: 25px;
}

.infoheader h3 {
    font-weight: 700;
    color: #435568;
}

.infoform .addressList li {
    padding: 0px;
    align-items: flex-start;
    flex-wrap: wrap;
}

.infoform .addressList li .addressleft {
    width: calc(100% - 40px);
    padding: 15px;
}

.daaresseditdelete {
    min-width: 25px;
    text-align: right;
    font-size: 32px;
    padding-right: 5px;
    color: #435568;
    position: relative;
}

.addressleft h4 {
    font-size: 18px;
    color: #435568;
    font-weight: 600;
    margin-bottom: 3px;
}

.addressleft p {
    margin-bottom: 3px;
    color: #435568;
}

.addressleft strong {
    color: #435568;
}

ul.editdeletepopup {
    padding: 0px;
    background: #fffef5;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 90px;
    position: absolute;
    right: 30px;
    top: 14px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    overflow: hidden;
}

ul.editdeletepopup li {
    width: 100%;
}

ul.editdeletepopup li a {
    text-decoration: none;
    font-size: 15px;
    padding: 3px 15px;
    color: #435568;
    font-weight: 500;
    display: block;
    width: 100%;
    text-align: left;
    transition: 0.5s;
}

.daaresseditdelete:hover ul.editdeletepopup {
    opacity: 1;
    visibility: visible;
}

.daaresseditdelete svg {
    cursor: pointer;
}

ul.editdeletepopup li a:hover {
    background: #6d2a5f;
    color: #fff;
}

.infoform ul.addressList {
    max-height: 500px;
    overflow: auto;
}

.infoform .detailTBOuter {
    border: 0px;
    padding: 0px;
}

.addEditAddressform {
    width: calc(100% - 30px);
    margin: 15px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
}

.addEditAddressform .form-control {
    width: 100%;
}

.addEditAddressform h4 {
    color: #435568;
    font-weight: 600;
    margin-bottom: 18px;
}

.addEditAddressform h4 {
    color: #435568;
    font-weight: 600;
    margin-bottom: 18px;
}

.enter-profile {
    position: relative;
    padding: 0px 0px 15px;
}

.wishlistOuter li {
    width: 25%;
}

.wishlistOuter li .itemContentOuter svg {
    color: #6d2a5f;
}

.headmain {
    display: flex;
    width: 100%;
}

.headmain .headone {
    width: 0%;
}

.headmain .headtwo {
    width: 50%;
}

.headmain .headtwo .selectbrand-outer {
    max-width: 98%;
    margin: 0 auto;
    border-radius: 0px 0px 3px 3px;
}

.conmpareproductimgOuter img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.conmpareproductimgOuter {
    margin-bottom: 10px;
}

.compareheading {
    text-align: center;
    margin-bottom: 20px;
}

.compareheading h2 {
    color: #6c2a5f;
    font-size: 32px;
    margin-bottom: 0px;
}

.compareheading h2+h4 {
    margin-top: 5px;
}

.compareTd.productImageOuterCompare {
    height: 200px;
    align-items: center;
    display: flex;
    overflow: hidden;
}

.showcompare {
    text-align: right;
    margin-bottom: 15px;
    padding-top: 30px;
}

.showcompare a {
    color: #6e2a5f;
    font-weight: 600;
}

.loginOuter {
    background-image: url(../src/img/white-BG.jpg);
    min-height: 70vh;
    padding: 50px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    background-position: center;
}

.loginformOuter {
    background: #fffef5;
    padding: 15px;
    border-radius: 5px;
}

.logoOuter img {
    width: 220px;
    margin: 0 auto;
}

.logoOuter {
    text-align: center;
    margin-bottom: 20px;
}

.forgetps a {
    text-decoration: none;
    color: #6d2a5f;
    font-weight: 500;
}

.loginformOuter h4,
.modal-header {
    font-weight: 700;
    color: #6d2a5f;
    font-size: 1.5rem;
}

.loginformOuter hr {
    border: 0px;
    opacity: 1;
    position: relative;
    height: 5px;
    background: #6d2a5f;
}

.account p {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #435568;
}

.account p a {
    text-decoration: none;
    color: #6d2a5f;
}

.conmpareproductimgOuter {
    display: none;
}

.customSelect {
    border-bottom: 1px solid #ccc;
}

.customSelect:last-child {
    border: 0px;
}

.headtwo h5 {
    background: #435568;
    border-radius: 3px 3px 0px 0px;
    color: #fffdf5;
    cursor: text;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 6px;
    padding: 12px 15px;
    width: calc(100% - 12px);
}

.productTopBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #6d2a5f;
    border-radius: 2px;
    padding: 15px;
    margin-bottom: 15px;
}

.productTopBar p {
    margin-bottom: 0px;
    color: #fff;
}

.productTopBar .btn-primary {
    background: #fffef5 !important;
    color: #6d2a5f !important;
}

.enter-profile::after {
    display: inline-block;
    margin-left: 0em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #435568;
}

.yourAccount {
    background: #fffef5;
    padding: 20px 0px 100px;
    border-top: 1px solid rgb(204 204 204 / 66%);
    min-height: 500px;
}


.accountInnner p {
    font-weight: 500;
    color: #435568;
    font-size: 20px;

}

.backpage {
    min-height: 46px;
}

.accountInnner hr {
    border-color: #ccc;
    opacity: 1;
}

.accountInnner h2 {
    font-size: 40px;
    font-weight: 500;
    color: #6d2a5f;
}

.accountseprate {
    width: calc(100% / 3);
    text-align: center;
    padding: 0px 15px;
}

.accountseprate a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 5px;
    text-decoration: none;
    color: #435569;
    border: 1px solid #dfdfdf;
    width: 100%;
    margin: 0 auto 30px;
    flex-direction: column;
    height: 320px;
    background: #fffef5;
    transition: 1s;
}

span.spetImg {
    width: 100%;
    text-align: center;
    font-size: 60px;
    margin-bottom: 24px;
    line-height: 0px;
}

.spetImg img {
    height: 80px;
    object-fit: contain;
}

.accountseprate a div {
    width: 100%;
    padding-left: 0px;
}

.accountseprate a h4 {
    font-weight: 600;
    font-size: 20px;
}

.accountseprate a div p {
    font-size: 14px;
    margin-bottom: 0px;
}

.cartheader {
    display: flex;
    justify-content: space-between;
}

.ordereditem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ordereditem p {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-right: 5px;
    color: #435568;
}

.ordereditem p span {
    font-weight: 600;
}

.searchProduct {
    width: 320px;
    background: #fffef5;
    border: 1px solid #ccc;
    border-radius: 55px;
    overflow: hidden;
    display: flex;
    margin-left: 10px;
}

.searchProduct .form-control {
    background: transparent;
    border: 0px;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 14px;
    padding-left: 24px;
    color: #435568;
}

.ordereditem .form-select {
    background-color: #fffef5;
    border-radius: 55px;
    font-size: 14px;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
}

.cartproductMain {
    background: #fffef5;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.cartproductdetails {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cartlistheader {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: 1px solid #ccc;
    background: #fffdee;
    border-radius: 5px 5px 0px 0px;
}

.cartleftheader {
    display: flex;
}

.orderplaced {
    position: relative;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #435568;
}

.orderplaced p {
    margin-bottom: 0px;
    position: relative;
    transition: 1s;
}

.orderplaced p a {
    text-decoration: none;
    color: #6d2a5f;
}

.orderplaced p span {
    font-weight: 500;
}

.cartlistheaderRight {
    display: flex;
    align-items: center;
}

.cartlistheaderRight p {
    font-size: 14px;
    font-weight: 700;
    margin: 0px;
    color: #435568;
}

.profilemaintap {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -15px;
}

.cartdetailsleft h5 {
    font-weight: 700;
    color: #435568;
    margin-bottom: 20px;
}

/* Chrome, Safari, Edge, Opera */
input.remove-control::-webkit-outer-spin-button,
input.remove-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number].remove-control {
    -moz-appearance: textfield;
    outline: none;
    border: none !important;
}

input[type=number].remove-control:focus {
    box-shadow: inset 0 0 0 #ddd;
    border: none !important;

}

.cartproductdetailstab {
    display: flex;
}

.cartproductdetailstab .cartproductimg {
    width: 240px;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
}

.cartproductimg img {
    border-radius: 5px;
}

.cartproductdetailstab .cartproductimg span {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -10px;
    bottom: -6px;
    border-radius: 50%;
    background-color: #6d2a5f;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.cartproductcontentdetails {
    width: calc(100% - 100px);
    padding-left: 25px;
}

.cartproductcontentdetails a {
    text-decoration: none;
}

.cartproductcontentdetails a h6 {
    color: #6d2a5f;
    transition: 1s;
    font-size: 22px;
    font-weight: 600;
}

.cartproductcontentdetails .btn-primary {
    padding: 5px 15px;
    font-size: 12px;
}

.cartproductcontentdetails a h6:hover {
    text-decoration: underline;
}

.cartdetailsright {
    width: 210px;
}

.cartdetailsright a {
    background: transparent;
    border: 1px solid #435568;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 55px;
    color: #435568;
    font-weight: 500;
    font-size: 14px;
    transition: 1s;
}

.cartdetailsright a:hover {
    background: #435568;
    color: #fffef5;
}

.customizationProduct {
    padding: 15px;
}

.customizationProduct ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
}

.customizationProduct ul li {
    width: 140px;
    border: 1px solid #ccc;
    padding: 5px;
    margin-right: 15px;
    border-radius: 5px;
}

.customizationContent {
    padding: 15px 0px 0px;
}

.customizationProduct ul li .img-fluid {
    background: #fff;
    height: 50px;
    object-fit: contain;
}

.customizationContent h5 {
    font-size: 15px;
    font-weight: 600;
    color: #6d2a5f;
}

.customizationContent p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #435568;
}

span.customizationprice {
    background: #6d2a5f;
    display: block;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0px 0px 5px 5px;
    margin-top: 6px;
}

.cartproductcontentdetails p {
    font-size: 14px;
    margin-bottom: 6px;
    color: #435568;
}

.cartproductcontentdetails p span {
    font-weight: 600;
}

.cartproductcontentdetails p span.d-block {
    font-weight: 400;
}

.stpes {
    width: 100%;
    margin-bottom: 15px;
}

.list-unstyled.multi-steps {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    width: 50%;

}

.list-unstyled.multi-steps li {
    font-weight: 500;
    background: #fffef5;
    position: relative;
    color: #98a2ac;
}



.list-unstyled.multi-steps li::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 2px;
    background: #cccccc;
    left: -27px;
    top: 12.1px;
    z-index: 10;
}

.list-unstyled.multi-steps li svg {
    color: #cccccc;
    position: relative;
    left: -11px;
    font-size: 15px;
}

.list-unstyled.multi-steps li:first-child::before {
    display: none;
}


.list-unstyled.multi-steps li a {
    color: #6d2a5f;
    text-decoration: none;
}

.list-unstyled.multi-steps li.is-active {
    color: #6d2a5f;
}

.list-unstyled.multi-steps li.is-active::before {
    background: #6d2a5f;
}

.list-unstyled.multi-steps li.is-active svg {
    color: #6d2a5f;
}

.cartproductcontentdetails p.customizedshaft {
    font-size: 12px;
    font-style: italic;
}

.tooltipbox {
    position: absolute;
    background: #fffdee;
    width: 210px;
    padding: 13px;
    border-radius: 5px;
    left: -50%;
    box-shadow: 0px 0px 9px #ccc;
    z-index: -50;
    transition: 1s;
}

.orderplaced p:hover .tooltipbox {
    z-index: 100;
}

.tooltipbox h5 {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 3px;
}

.tooltipbox p {
    font-size: 12px;
    font-weight: 500;
}

.tooltipbox::before {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    position: absolute;
    top: -6px;
    transform: rotate(180deg);
    left: 59%;
    color: #fffdee;
}

ul.ratinglist {
    padding: 0px;
    list-style: none;
    display: flex;
    margin-bottom: 10px;
    position: relative;
    margin-bottom: 30px;
}

ul.ratinglist small.invalid-feedback-text.form-text {
    bottom: -22px;
}

ul.ratinglist li a {
    font-size: 24px;
    color: #f7d800;
}

.orderdetailsrightpert {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.orderdetailsrightpert .totals {
    text-align: left;
    font-size: 22px;
    color: #435568;
}

.orderdetailsrightpert .totals span {
    font-size: 14px;
}

.addressList ul {
    padding: 0px;
    margin: 0px -10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.addressList ul li {
    width: calc(100% / 3 - 20px);
    padding: 0px 10px;
}

.addressList ul li .listaddress {
    -webkit-box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
    margin-bottom: 25px;
    background-color: #fffef5;
    border-radius: 5px;
    transition: 1s;
}

.addressList ul li .listaddress:hover {
    -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.badgeClass {
    background-color: #6d2a5f;
}

.badgeClassPointer {
    cursor: pointer;
}

.innerdetails {
    padding: 20px;
    min-height: 180px;
    position: relative;
}

.addressfooter {
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.addressfooter button {
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px;
    text-decoration: none;
    color: #435568;
    font-weight: 600;
    transition: 1s;
    font-size: 14px;
}

.addressfooter button:first-child {
    border-right: 1px solid #ccc;
}

.addressfooter button:hover {
    color: #6d2a5f;
}

.addressList label {
    margin-bottom: 6px;
}

.addressList ul li h5 {
    font-size: 16px;
    color: #6d2a5f;
}

.addressList ul li p {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 0px;
    font-weight: 400;
}

.addressList ul li p a {
    display: inline-block;
    color: #435568;
    text-decoration: none;
    font-weight: 600;
    position: absolute;
    top: 15px;
    right: 15px;
    background: #e4e4e4;
    padding: 2px 11px;
    border-radius: 55px;
    font-size: 10px;
}

.backpage {
    display: flex;
    justify-content: space-between;
}

.backpage div {
    display: flex;
    align-items: center;
}

.backpage div a {
    text-decoration: none;
    color: #6d2a5f;
    font-weight: 500;
}

.navbar-expand-lg .navbar-nav>a,
.navbar-expand-lg .navbar-nav>li>a,
.navbar-expand-lg .navbar-nav .dropdown>a {
    padding: 40px 15px !important;
    font-size: 16px;
    white-space: nowrap;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    margin: 0px;
}

.navbar-expand-lg .navbar-nav li {
    position: relative;
}

ul.sortmenu {
    position: absolute;
    background: #fffef5;
    border-radius: 0px 0px 5px 5px;
    padding: 0px;
    list-style: none;
    min-width: 190px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    padding-bottom: 10px;
}

.navbar-expand-lg .navbar-nav li:hover ul.sortmenu {
    opacity: 1;
    visibility: visible;
}

.navbar-expand-lg .navbar-nav li a.nav-link::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.navbar-expand-lg .navbar-nav li ul.sortmenu a {
    padding: 5px 20px !important;
    display: block;
    white-space: nowrap;
}

.checkoutleft+.totals {
    padding: 10px;
}

p.logincontent {
    margin-bottom: 5px;
    font-size: 15px;
    color: #435568;
}

.stepOne {
    background: #fffef5;
    padding: 35px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 30px;
}

.stepOne .form-group {
    margin-bottom: 25px;
}

small.invalid-feedback-text {
    position: absolute;
    margin: 0px;
    color: red;
    white-space: nowrap;
    font-weight: 500;
}

.savecardouter {
    background: #fffef5;
    padding: 0px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 10px 21px #e3e3e3;
    height: calc(100% - 30px);
    min-height: 198px;
    margin-bottom: 30px;
}

.cardleft-panel a {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    font-weight: 600;
    color: #435568;
    text-decoration: none;
}

.cardleft-panel a img {
    width: 35px;
    object-fit: cover;
}

.cardleft-panel a span {
    display: inline-block;
    width: 49px;
    overflow: hidden;
    margin-right: 12px;
}

.cardbody {
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.cardbody-left {
    width: 50%;
}

.cardbody-right {
    width: 50%;
}

.cardbody-left h5,
.cardbody-right h5 {
    font-size: 18px;
    font-weight: 600;
    color: #435568;
    margin-bottom: 0px;
}

.cardbody-left p,
.cardbody-right p {
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
}

.cardbody-right p span {
    display: block;
}

.cardbody-right p strong {
    display: block;
    color: #435568;
}

.removeedit {
    text-align: right;
    padding: 10px 15px;
    border-top: 1px solid #ccc;
    margin-top: 10px;
}

.addnewCard {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.addnewCard a {
    text-decoration: none;
    color: #435568;
}

.addnewCard a svg {
    font-size: 36px;
    margin-bottom: 15px;
}

.addnewCard a h4 {
    margin: 0px;
    font-weight: 500;
}

.cptch_block {
    display: flex;
    align-items: center;
}

.cptch_block .form-control {
    width: 50px;
    margin-left: 10px;
}

.custom_check small.invalid-feedback-text {
    bottom: -21px;
    left: 0px;
    font-weight: normal;
    font-size: 13px;
}

button.applied-coupon {
    padding: 5px 10px !important;
    font-size: 12px;
}

button.applied-coupon svg {
    font-size: 17px;
    margin-left: 6px;
}

.applied-coupon+span {
    font-weight: 600;
    margin-left: 5px;
    font-size: 14px;
    color: green;
}

address.fulladdress {
    margin: 0px;
    font-style: italic;
}

.cartproductImg .img-fluid {
    height: 46px;
    object-fit: contain;
    transition: 1s;
}

.emptyaddressmsg {
    padding: 15px 25px;
    text-align: center;
    font-weight: 600;
    color: #435568;
}

.PhoneInputInput {
    outline: none !important;
    background: transparent;
    border: 0px;
    box-shadow: none !important;
}

ul.cat-additional-pModel {
    padding: 0px;
    list-style: none;
}

ul.cat-additional-pModel li {
    display: flex;
    padding-left: 15px;
}

ul.cat-additional-pModel li .cartproductImg {
    min-width: 70px;
    height: 40px;
}

ul.cat-additional-pModel li .cartproductImg img {
    height: 38px;
}

ul.cat-additional-pModel li .cart-partleft {
    width: calc(100% - 40px);
    align-items: flex-start;
    margin-top: 5px;

}

.substores {
    background-color: #435568;
    width: 90%;
    max-width: 1290px;
    margin: 0 auto;
    height: 355px;
    text-align: left;
    position: absolute;
    left: 5%;
    top: 100%;
    right: 5%;
    padding: 0px;
    list-style: none;
    border-radius: 0px 0px 0px 5px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: .5s;
    display: flex;
    justify-content: center;
}

.titlelist {
    padding: 0px;
    margin: 0px;
    list-style: none;
    width: 200px;
    border-right: 1px solid #fffef5;
}

.navbar-expand-lg .navbar-nav li:hover .substores {
    opacity: 1;
    visibility: visible;
}


.navbar-expand-lg .navbar-nav .substores li a {
    display: block;
    color: #fff;
    padding: 5px 15px !important;
}

.navbar-expand-lg .navbar-nav .substores>li>a:hover {
    background: #6d2a5f;
}

.shaftsubmenu {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.navbar-expand-lg .navbar-nav .substores li .shaftsubmenu>li>a {
    font-size: 14px;
    position: relative;
    left: 0;
    transition: 1;
}

.navbar-expand-lg .navbar-nav .substores li .shaftsubmenu>li>a:hover,
.navbar-expand-lg .navbar-nav .substores li .shaftsubmenu>li.active a {
    left: 0;
    position: relative;
    background: #0000002e;
}

.listdata {
    -webkit-column-count: 5;
    column-count: 5;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    list-style: none;
    margin: 0;
    padding: 8px 10px;
    width: calc(100% - 200px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
    -moz-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    height: 23em;
}

ul.catgoreylistdata>li>ul {
    position: relative;
    padding: 0px;
    list-style: none;
    margin: 0px;
    width: 100%;
}

ul.catgoreylistdata {
    display: flow-root;
    padding: 0px;
    list-style: none;
    margin: 0px;
}

.navbar-expand-lg .navbar-nav .listdata li a {
    color: #fffef5;
    font-size: 14px;
    position: relative;
    transition: 1s;
    display: inline-block;
    left: 0px;
}

.navbar-expand-lg .navbar-nav .listdata li a:hover {
    left: 10px;
}

.titlelist>li:last-child {
    border: 0px;
}

.searchdealers {
    position: relative;
}

.searchdealers .btn.btn-primary {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 0px;
    height: initial;
    min-width: 50px;
    height: 100%;
}

.Dealerbtn.btn.btn-primary {
    height: 50px;
    border-radius: 55px !important;
    padding: 10px 15px;
}

.uscompanylogos {
    display: flex;
    background: #fffef5;
    padding: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.uscompanylogos img {
    height: 70px;
}

.listbody {
    padding: 25px 15px;
    margin-bottom: 43px;
    height: calc(100% - 43px);
    background: #e8e5e0;
}

.dealerslistouter {
    margin-top: 10px;
}

.dealerslistouter h4 {
    font-weight: 700;
    color: #435568;
    margin-bottom: 15px;
}

.listbody a {
    color: #6d2a5f;
    text-decoration: none;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
    font-size: 21px;
}

.listbody img {
    filter: contrast(0.5);
    max-width: 95%;
    height: 120px;
    object-fit: contain;
}

.listbody .companylogo img {
    filter: contrast(0.5);
    width: 320px;
    height: 120px;
    object-fit: cover;
    display: block;
}

ul.infolistdetails {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

ul.infolistdetails li {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    word-break: break-word;
}

ul.infolistdetails li a {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    word-break: break-word;
}

ul.infolistdetails li svg {
    position: absolute;
    left: 0px;
    top: 4px;
}

ul.infolistdetails li:last-child {
    margin-bottom: 0px;
}

.listbody a+a {
    min-height: 55px;
    margin-bottom: 7px;
}

.viewlocation {
    margin: 15px 0px 0px;
    font-size: 15px;
    color: #6d2a5f;
    font-weight: 600;
    display: block;
    cursor: pointer;
}

.viewlocation svg {
    position: relative;
    top: -1px;
}

.dealerslocationlist .companylogo img {
    width: 250px;
}

.dealerslocationlist .companylogo {
    display: block;
}

.dealerslocationlist>a+a {
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    margin: 23px 0px 25px;
    color: #6d2a5f;
    display: block;
}

.dealerslocationlist ul {
    padding: 0px;
    list-style: none;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dealerslocationlist ul li a {
    color: #435568;
    text-decoration: none;
    padding-left: 25px;
    position: relative;
    display: block;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
    word-break: break-all;
}


.dealerslocationlist ul li a svg {
    position: absolute;
    left: 0px;
    top: 4px;
}

.dealerslocationlist ul li:last-child a {
    margin: 0px;
}

.dealerslocationlist ul li a:hover {
    color: #6d2a5f;
}

.modal-header button.btn-close {
    box-shadow: 0px 0px 5px #ccc;
    font-size: initial;
}

.dealerslocationlist ul li a span {
    display: block;
}

.dealerslocationlist ul li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 48%;
    padding-right: 15px;
}

.dealerslocationlist ul li h4 {
    color: #435568;
    font-weight: 600;
    font-size: 20px;
}

.dealerslocationlist {
    padding: 15px;
}

.viewlocation a {
    font-size: 16px;
}

.modal-header a::before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 90px;
    height: 5px;
    z-index: 100;
    background: #6d2a5f;
}

.navbar-expand-lg .navbar-nav .substores ul.titlelist>li>a {
    font-weight: bold;
    font-size: 18px;
}

.navbar-expand-lg .navbar-nav .substores ul.titlelist>li a.active,
.navbar-expand-lg .navbar-nav .substores ul.titlelist>li a:hover {
    background: #fffef5;
    color: #435568;
}

h5.customizationProductbottomheading {
    margin-top: 26px;
}

.paginations {
    margin: 40px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginations ul.pagination li.page-item {
    margin: 0px 10px;
}

.paginations ul.pagination li.page-item a.page-link {
    width: 40px;
    height: 40px;
    border-radius: 55% !important;
    overflow: hidden;
    background: #fffef5;
    color: #6d2a5f;
    border-color: #6d2a5f;
}

.paginations ul.pagination li.page-item span.page-link,
.paginations ul.pagination li.page-item a.page-link:hover {
    background: #6d2a5f;
    border-color: #6d2a5f;
    border-radius: 55%;
    color: #fffef5;
}

.wishlistmain .cartproductdetails {
    background: #fffef5;
    align-items: center;
    margin-bottom: 20px;
    padding: 25px;
}

.cartdetailsright button.removeitem {
    padding: 0px;
    border: 0px;
    text-decoration: underline;
    margin-top: 11px;
    display: inline-block;
    background: transparent !important;
    color: #435568 !important;
}

.wishlistmain .cartdetailsright {
    text-align: center;
}

.itemrating ul.ratinglist li a {
    line-height: 15px;
    font-size: 20px;
}

.itemrating ul.ratinglist {
    margin: 0px;
}

.productrate span {
    font-weight: 600;
    color: #6d2a5f;
    display: inline-block;
    margin: 0px 0px;
    font-size: 17px;
    position: relative;
    left: -10px;
}

.productrate span svg {
    position: relative;
    left: 5px;
    top: -2px;
}

.wishlistmain .cartproductcontentdetails a h6,
.wishlistmain .cartproductcontentdetails p {
    margin-bottom: 0px;
}

.infoform p label {
    width: 160px;
}

.infoform p {
    margin-bottom: 18px;
}

.orderdetailsheader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.downloadinvoice.btn.btn-primary {
    background: transparent !important;
    color: #6d2a5f !important;
    border: 0px !important;
    padding: 0px;
}

.orderdetailsheader ul {
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.downloadinvoice.btn.btn-primary svg {
    position: relative;
    left: -4px;
    top: -1px;
}

.orderdetailsheader ul li {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    padding-right: 10px;
    position: relative;
    line-height: 17px;
    color: #435568;
}

.orderdetailsheader ul li:first-child:before {
    content: "";
    position: absolute;
    right: 0px;
    height: 100%;
    background: #435568;
    width: 0.5px;
}

.downloadinvoice.btn.btn-primary svg {
    position: relative;
    left: -4px;
    top: -1px;
}

.detailsoforder {
    background: #fffef5;
    padding: 20px;
    position: relative;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.shippingdetails {
    width: 30%;
}

.shippingdetails h5 {
    font-size: 16px;
    color: #435568;
    font-weight: 600;
    margin: 0px 0 10px;
}

.shippingdetails p {
    font-size: 12px;
    margin: 0px;
    font-weight: 500;
    line-height: 18px;
}

.shippingdetails p span {
    display: block;
}

.shippingdetails .paymentmathed {
    font-size: 12px;
    font-weight: 500;
}

ul.ordersummerydetails {
    padding: 0px;
    list-style: none;
    margin: 0px;
}

ul.ordersummerydetails li {
    font-size: 12px;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    line-height: 23px;
}

ul.ordersummerydetails li:last-child {
    font-weight: 700;
}

ul.ordersummerydetails li span svg {
    position: relative;
    top: -1px;
    left: 4px;
}

.checkoutRight.yourreviewright {
    margin-top: 28px;
    min-height: calc(100% - 28px);
}

.cartproductdetailstab.customizationProductbottom.mt-3 .cartproductimg .img-fluid {
    height: 90px;
    width: 100%;
    object-fit: contain;
}

.cartproductdetailstab.customizationProductbottom.mt-3 .cartproductimg {
    background: #fff;
}

span.clientname {
    bottom: initial;
    font-size: 12px;
    font-weight: 600;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    white-space: pre-line;
    top: 38px;
    line-height: 12px;
    white-space: nowrap;
        width: 80px;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
}

li.add-new-address label {
    width: calc(100% - 34px);
}

li.add-new-address input {
    width: 20px;
    height: 20px;
}

.empty-cart {
    min-height: calc(100vh - 600px);
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
    background: transparent;
    padding: 40px 0px;
}

.empty-cart h3 {
    margin-bottom: 18px;
}

.find-store {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.find-store h5 {
    margin: 0px;
    font-weight: 600;
    color: #6d2a5f;
    cursor: pointer;
    font-size: 17px;
}

.find-store h5 svg {
    font-size: 40px;
}

.find-store .form-group {
    position: relative;
    margin: 0px;
    margin-left: 15px;
    width: calc(100% - 250px);
    display: flex;
    align-items: center;
}

.find-store .form-group svg {
    position: absolute;
    top: 11px;
    left: 11px;
    font-size: 25px;
    color: #435568;
}

.find-store .form-group input.form-control {
    margin-left: 10px;
    width: 100px;
    /* text-align: center; */
}

.selectfilters {
    display: flex;
    justify-content: space-between;
    padding: 40px 0px 50px;
    overflow: auto;
}

.selectfilters a {
    transition: 1s;
    background: #fffef6;
    text-decoration: none;
    padding: 3px 14px;
    border-radius: 55pc;
    color: #6d2a5f;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
}

.selectfilters a.active,
.selectfilters a:hover {
    background: #6d2a5f;
    color: #fffef6;
}

.searchaddressinput {
    margin-bottom: 5px;
}

.searchaddressinput input.form-control {
    background: #fffef5;
}

.infochanges+.contactinfo .searchaddressinput {
    margin-top: 20px;
}




/* Web Loader css start now */
.loader-outer {
    position: fixed;
    background: #fffef5eb;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader1 {
    display: inline-block;
    font-size: 0px;
    padding: 0px;
}

.loader1 span {
    vertical-align: middle;
    border-radius: 100%;
    background-color: #6d2a5f;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 3px 2px;
    -webkit-animation: loader1 0.8s linear infinite alternate;
    animation: loader1 0.8s linear infinite alternate;
}

.loader1 span:nth-child(1) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.loader1 span:nth-child(2) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.loader1 span:nth-child(3) {
    -webkit-animation-delay: -0.26666s;
    animation-delay: -0.26666s;
}

.loader1 span:nth-child(4) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;

}

.loader1 span:nth-child(5) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@keyframes loader1 {
    from {
        transform: scale(0, 0);
    }

    to {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes loader1 {
    from {
        -webkit-transform: scale(0, 0);
    }

    to {
        -webkit-transform: scale(1, 1);
    }
}

/* End */


.submenu-outer ul.submenuList>li>a {
    color: #6d2a5f !important;
    padding: 10px 5px !important;
    white-space: nowrap;
    font-weight: 600;
}

footer .navbar-expand-lg .navbar-nav {
    margin: 0 auto;
}

.navbar-expand-lg .navbar-nav ul.meghaMenu li a:hover {
    color: #6d2a5f !important;
}

.giftcard {
    position: relative;
    overflow: initial;
    margin-bottom: 24px;
}

.giftcard small.invalid-feedback-text {
    position: absolute;
    top: 43px;
    padding-left: 14px;
}

.thankyou-outer {
    text-align: center;
    padding: 30px 0px;
}

.thankyou-outer img {
    width: 170px;
    margin-bottom: 30px;
}

.thankyou-outer h3 {
    background: transparent;
    border: 0px;
    color: #6d2a5f;
    font-weight: bold;
    font-size: 31px;
    padding: 0px;
    margin: 0px 0 16px;
}

.thankyou-outer h4 {
    margin-bottom: 20px;
    color: #000 !important;
    font-size: 18px;
}

.thankyou-outer h5 {
    margin-bottom: 20px;
}

.contactinfo {
    position: relative;
    margin-top: 15px;
}

.contactinfo small.invalid-feedback-text {
    bottom: -23px;
}

.termsmodal .modal-dialog {
    width: 100%;
    min-width: 70%;
    margin-top: 125px;
}

.termsmodal .modal-title.h4 {
    font-size: 27px;
    color: #435568;
    font-weight: 600;
}

.termsmodal p {
    color: #435568;
    font-size: 15px;
    line-height: 26px;
}

.giftcard.gift-error {
    margin-bottom: 47px;
}

.giftcard.gift-error small.invalid-feedback-text.form-text {
    white-space: pre-line;
}




.wrap {
    background: #fffef5;
    display: table-row-group;
    font-size: 100%;
    margin: auto;
    max-width: 100%;
    text-align: center;
    width: 100%;
}

.wrap .td,
.wrap .wrap .td {
    border: 1px solid #ccc;
    display: table-cell;
}

.wrap .td {
    vertical-align: top;
}

.wrap h2 {
    font-size: 100%;
    margin: 0;
    padding: 5px;
    white-space: nowrap;
    background-color: #e7e5df;
    min-height: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap .wrap .td {
    border-bottom: none;
    border-left: none;
    height: 40px;
    padding: 0px;
    width: 100vw !important;
}

.wrap .td .td:last-child {
    border-right: 0;
}

.wrap .td:first-child .wrap .td {
    max-width: 33.33%;
    min-width: 33.33%;
    width: 33.33%;
}

.detailTBOuter {
    padding: 60px 25px;
    border-top: 0px;
    margin-bottom: 15px;
    background: #fffef5;
    overflow: auto;
}

.spec-head+.wrap .td {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
}



.compare-product-list {
    background: #fffef5;
    border: 1px solid #d9dde0;
    margin: 20px 0 0;
    padding: 10px;
}

.compare-table {
    border: 1px solid #d9dde0;
    border-bottom: 0px;
}

.compare-head {
    display: flex;
    justify-content: space-between;
}

.compare-head .compare-leftpart,
.compare-head .compare-center,
.compare-head .compare-right {
    border-bottom: 1px solid #d9dde0;
    border-right: 1px solid #d9dde0;
    box-sizing: border-box;
    padding: 14px 15px;
    background: #435468;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.compare-leftpart {
    width: 40%;
}

.compare-center,
.compare-right {
    text-align: center;
    width: 30%;
}

.compare-head .compare-right {
    border-right: 0px;
}

.compare-body {
    display: flex;
}

.bodytr {
    display: flex;
    width: 100%;
}

.partleft {
    align-items: center;
    display: flex;
    width: 40%;
}

.compareInner {
    border-bottom: 1px solid #d9dde0;
    border-right: 1px solid #d9dde0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    min-width: 100px;
}

.compareInner+.compareInner {
    min-width: 140px;
}

.compareInner .compareTd,
.compareOuterpanel .compareTd {
    border-bottom: 1px solid #d9dde0;
}

.compareTd {
    min-height: 42px;
}

.compareInner h2 {
    box-sizing: border-box;
    font-size: 16px;
    margin: 0;
    padding: 10px;
    color: #435468;
    font-weight: 600;
    word-break: break-all;
}

.compareOuterpanel {
    display: flex;
    text-align: center;
    width: 30%;
    flex-direction: column;
}

.multipal-options .compareOuterpanel {
    flex-direction: column;
}

.basic-information .compareOuterpanel {
    flex-direction: column;
}

.basic-information .compareTd {
    height: 42px !important;
}

.basic-information .bodytr .compareOuterpanel .compareTd.productImageOuterCompare {
    height: 200px !important;
    width: initial !important;
}

.basic-information .partleft .compareTd.productImageOuterCompare {
    height: 199px !important;
}

.compareOuterpanel .compareTd {
    align-items: center;
    border-right: 1px solid #d9dde0;
    display: flex;
    color: #435468;
    font-weight: 500;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.bodytr:last-child .compareOuterpanel .compareTd:first-child {
    border-bottom: 1px solid #d9dde0 !important;
}

.compareOuterpanel:last-child .compareTd {
    border-right: 0px;
}

.compareTd:nth-child(even) {
    background-color: #fffdf5;
}

.compareTd:nth-child(odd) {
    background-color: #e7e5df;
}

.compareOuter .box-outer {
    padding: 0px;
}

.compareInner:nth-child(2) .compareTd:last-child {
    border-bottom: 0px;
}

.compare-footer div {
    background: transparent !important;
}

.compare-bodyone .compare-body:nth-child(even) .compareTd {
    background-color: #fffdf5;
}

.compare-bodyone .compare-body:nth-child(odd) .compareTd {
    background-color: #e7e5df;
}

.compare-bodyone .compare-body:nth-child(even) .compareInner {
    background-color: #fffdf5;
}

.compare-bodyone .compare-body:nth-child(odd) .compareInner {
    background-color: #e7e5df;
}

.compareInner:first-child .compareTd {
    border: 0px;
}

.whiteBox.mb-3 {
    height: 100%;
}

.cartdetailsleft {
    width: calc(100% - 280px);
}


.checkoutproductdetails.check-out-details-panel {
    display: flex;
}

.checkoutproductdetails.check-out-details-panel .check-out-panel-left {
    width: 70%;
}

.checkout-review {
    width: 30%;
}

.checkout-review .checkoutRight.yourreviewright {
    margin: 0px;
    padding: 15px 20px;
    border: 0px;
    border-left: 1px solid #ccc;
    border-radius: 0px;
    min-height: 100%;
}

/* Technology page css start now */
.technology .product-heading h2 {
    text-align: left;
    font-size: 40px;
    font-weight: 700;
    color: #435568;
}

.thechnology-small {
    position: absolute;
    width: 200px;
    right: 30px;
    top: 60px;
    border: 5px solid #fff;
    border-radius: 15px;
    height: 200px;
    object-fit: cover;
}

.technology-imgs {
    position: relative;
}

img.height-fix {
    height: 680px;
    width: 80%;
    border-radius: 20px;
    box-shadow: 0px 0px 20px #d6d6d6;
    object-fit: cover;
}

.technology .product-heading p.text-start {
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
}

.technology p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
}

.compareOuter.technology {
    background-size: cover;
    background-position: center;
    padding-bottom: 0px;
}

.technology-tabs {
    background-image: url(../src/img/Kinetixx-background.jpg);
    padding: 50px 0px;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
}

.technology-tabs::before {
    content: "";
    position: absolute;
    background: #000;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0;
    z-index: 0;
}

.technology-tabs .container {
    position: relative;
    z-index: 10;
}

.technology-mat-content {
    color: #fff;
}

.technology-mat-content hr {
    width: 50px;
    border: 0px;
    height: 5px;
    background: #fff;
    opacity: 1;
    margin: 30px auto;
}

.technology-mat-content h2 {
    font-size: 40px;
    font-weight: 700;
}

.technology-mat-content h4 {
    font-weight: bold;
    font-style: italic;
    color: #6d2a5f;
    margin: 0px 0px 15px 0px;
    font-size: 27px;
}

.technology-tabs .technology-mat-content h4 {
    color: #ffffff;
}

.facility-outer {
    background-image: url(../src/img/white-BG.jpg);
    padding: 50px 0px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.application .technology-mat-content {
    color: #435569;
    position: relative;
    z-index: 1;
    padding: 30px 0px 0px;
}

/* .application .technology-mat-content h4 {
    font-size: 40px;
    text-transform: uppercase !important;
    letter-spacing: -2px;
    font-family: sans-serif;
    font-weight: 900;
    font-style: inherit;
    color: #000;
    margin: 0px;
} */

.technology-mat-content h2 {
    font-size: 28px;
    margin: 10px 0px;

}

.technology-mat-content h2 {
    font-size: 40px;
    font-weight: 700;
}

.facility-outer .technology-mat-content {
    color: #435568;
}

.facility-outer .technology-mat-content p {
    color: #1e2936;
}

.facility-outer .technology-mat-content hr {
    background: #435569;
}

.store-banner {
    background-image: url(../src/img/store-head.png);
    padding: 0px;
    background-size: cover;
}

.store-banner .headingContent h2 {
    color: #fffef5;
}

.store-banner .headingContent h2 sup {
    color: #fffef5;
    border-color: #fffef5 !important;
}

.store-banner .headingContent h4 {
    font-size: 23px;
    font-weight: 600;
    color: #fffef5;
}

.compareOuter.technology h4 {
    color: #fffdf5;
    text-shadow: 0px 0px 8px #435568;
}

.store-banner img {
    width: 162px;
}

.technology .product-heading h2 {
    text-align: center;
}

.technology .product-heading hr {
    width: 70px;
    margin: 30px auto;
    height: 7px;
    border: 0px;
    background: #435568;
    opacity: 1;
}

.patents-outer {
    background-image: url(../src/img/Kinetixx-background.jpg);
    padding: 100px 0px;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
}

.patents-outer+.exclusiveUpdates {
    display: none;
}

/* Modal Css */
.modal-content {
    background: #fffef5;
    border-radius: 5px;
    max-height: 500px;
}

.modal-title.h4 {
    font-weight: 700;
    color: #6d2a5f;
}

.modal-header {
    border: 0px;
    position: relative;
}

.modal-header hr {
    border: 0px;
    opacity: 1;
    height: 5px;
    background: #6d2a5f;
    position: absolute;
    left: 17px;
    right: 10px;
    bottom: -17px;
}

.modal-body {
    padding-top: 30px;
    overflow: auto !important;
}

.btn-secondary {
    border-radius: 55px;
}

.modal-body .creditcardform {
    border: 0px;
    padding: 0px;
    margin: 0px;
}

.cartproductcontentdetails h5 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.addressList {
    margin: 20px 0px 10px;
}

.addressfooter a {
    text-decoration: none;
    color: #435568;
    padding: 5px 0px;
}



span.usercontentdetails {
    display: none;
    position: absolute;
    width: 250px;
    background: #fffdee;
    z-index: 100;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    top: 47px;
    left: -72px;
}

.orderplaced:hover span.usercontentdetails {
    display: block;
}

.usercontentdetails address {
    margin: 0px;
    font-size: 11px;
    margin: 5px 0px 0px 0px;
}

.usercontentdetails h6 {
    margin: 0px;
    font-size: 15px;
}

span.usercontentdetails::before {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    position: absolute;
    top: -7px;
    transform: rotate(180deg);
    left: 50%;
    color: #ccc;
}

.edit-form {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    position: relative;
}

.edit-form small.invalid-feedback-text {
    bottom: -25px;
    left: 161px;
}

.edit-form label.form-label {
    margin: 0px;
    min-width: 160px;
}

.edit-form .PhoneInputInput {
    width: 100%;
}

.special_pass small.invalid-feedback-text {
    position: initial;
    width: 100%;
    white-space: pre-line;
}

.pac-container {
    background-color: #FFF;
    z-index: 10000;
    position: fixed;
    display: inline-block;
    float: left;
}

.modal {
    z-index: 900;
}

.modal-backdrop {
    z-index: 800;
}

.patent-count p {
    position: relative;
}

.patent-count p::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    top: 7px;
    left: -21px;
}

.contact-details iframe {
    width: 100%;
    border: 0px;
}

.review-panel-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px
}

.review-panel-outer h4 {
    margin: 0px;
}

.readonly-review {
    padding: 10px 0px 0px;
}

span.divaider {
    width: 0.5px;
    background: #c9c9c9;
}

.getintuch {
    padding: 35px 30px;
    background-color: #fffef5;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.inquiries-list ul {
    list-style: none;
    padding: 0px;
    margin-left: -10px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
}

.inquiries-list ul li {

    width: calc(100% / 3 - 10px);
    padding-left: 10px;
    padding-right: 10px;
}

.inquiries-list ul li div {
    font-size: 16px;
    color: #435569;
    margin-bottom: 22px;
    background: #fffef5;
    padding: 30px 15px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    text-align: center;
    height: calc(100% - 22px);
}

.inquiries-list ul li a {
    display: block;
    text-decoration: none;
    color: #435568;
}

.inquiries-list ul li span {
    margin-bottom: 4px;
    display: block;
    color: #6d2a5f;
    font-weight: 600;
}

.company-address.ourProduct {
    padding: 0px;
}

.company-address iframe {
    width: 100%;
    margin-top: 100px;
}

section.company-address.ourProduct {
    padding: 0px;
}

.headOuter {
    position: relative;
}

.officeaddress {
    background: #fffef5;
    padding: 40px;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 10px 10px #00000021;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
}

.officeaddress address strong {
    display: block;
    margin-bottom: 10px;
    color: #6d2a5f;
}

.kinetixx-about {
    padding: 100px 0px;
}

.about-content h2 {
    font-size: 40px;
    font-weight: 700;
    color: #435568;
}

.about-content p {
    font-size: 17px;
    line-height: 30px;
    color: #435568;
}

section.about-video {
    position: relative;
}

.overlay-content {
    position: absolute;
    text-align: center;
    background: #00000075;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.overlay-content svg {
    font-size: 90px;
    margin-bottom: 30px;
    cursor: pointer;
}

.overlay-content h2 {
    font-size: 40px;
    font-weight: 700;
}

.video-modal iframe {
    width: 100%;
}

.video-modal .modal-body {
    padding: 10px;
    overflow: hidden;
}

.edit-form select.form-select,
.edit-form .form-control {
    background-color: initial;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: none !important;
    color: #435568;
    min-height: 45px;
}

.usercontentdetails h6 a {
    color: #6d2a5f;
}

.uslinks {
    text-align: right;
    margin-bottom: 20px;
}

.uslinks .Link {
    font-weight: 500;
    color: #6d2a5f;
}

ul.infolistdetails li span {
    display: block;
}

.more-items {
    font-weight: 600;
    color: #6d2a5f !important;
    display: inline-block;
    text-decoration: none;
}

.forgetps a {
    white-space: nowrap;
}

.addressList h1 {
    font-size: 27px;
}

.modal .creditcardform .form-group svg {
    color: #435568;
    font-size: 19px;
    position: absolute;
    right: 12px;
    top: 1px;
    width: 27px;
}

.showcompare button.btn-link.btn.btn-link {
    padding: 0px;
    color: #6e2a5f;
    font-weight: 600;
}

.alltypes p strong {
    font-weight: 500;
}

.bottomtabs .nav-tabs.gears {
    justify-content: flex-start;
}

.bottomtabs .nav-tabs.gears li.nav-item {
    width: auto;
}

.bottomtabs .nav-tabs.gears li.nav-item button {
    padding: 5px 50px;
}

.inquiries-list ul li div svg {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 21px;
    color: #435568;
}

.Get-connect {
    padding: 50px 0px 80px;
    background-image: url(../src/img/top-right.png), url(../src/img/left-bottom.png);
    background-size: 40%;
    background-position: top right, bottom left;
    background-repeat: no-repeat;
    background-color: #e8e5e0;
    background-attachment: fixed;
}

ul.infolistdetails li a {
    color: #6d2a5f;
    text-decoration: none;
}


/* Inner page css start */
.InnerPage img, img.Innerbanner {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    opacity: .3;
}


.InnerPage {
    position: relative;
}

.banner-contentInner {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 0px;
    bottom: 0px;
    background: rgb(18 18 18 / 3%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner-contentInner h2 {
    font-size: 40px;
    font-weight: 700;
    color: #435568;
}

.banner-contentInner h4 {
    font-weight: bold;
    font-style: italic;
    color: #435568;
    margin: 0px 0px 15px 0px;
    font-size: 27px;
}

.facility-outer .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
}

.technology-mat-content .image-gallery {
    width: 50%;
    display: inline-block;
    float: left;
    margin-right: 25px;
    margin-bottom: 25px;
}

.technology-tabs .image-gallery {
    float: right;
    margin-left: 25px;
    margin-bottom: 25px;
}

.mediaOuter {
    display: flex;
    margin-bottom: 20px;
}

.mediapanel {
    max-width: 390px;
    overflow: hidden;
    width: 390px;
}

.mediaOuter {
    display: flex;
    margin-bottom: 40px;
}



.media-content {
    padding-left: 25px;
    width: calc(100% - 390px);
}

.media-content h4 a {
    color: #435568;
    font-weight: 700;
    text-decoration: none;
    font-size: 30px;
}

.mediapanel img {
    height: 212px;
    object-fit: cover;
    width: 100%;
}

span.dateTime {
    color: #435568;
    margin: 15px 0px;
    display: block;
    font-size: 13px;
    font-weight: 500;
}

.media-content p {
    font-size: 16px;
    font-weight: 500;
    color: #435568;
    line-height: 30px;
    word-wrap: break-word;
}

.technology-tabs+.exclusiveUpdates.text-center {
    display: none;
}

.technology-mat-content .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
}

.image-gallery-icon:hover {
    color: #6d2a5f !important;
}

.technology-mat-content .image-gallery-left-nav .image-gallery-svg,
.technology-mat-content .image-gallery-right-nav .image-gallery-svg {
    height: 70px;
    width: 30px;
}

.Newsdetails {
    text-align: center;
    margin: 50px 0px;
}

.Newsdetails h3 {
    color: #435568;
    font-weight: 700;
    text-decoration: none;
    font-size: 40px;
    margin: 30px 0px;
}

.newsImage img {
    width: 100%;
    margin-top: 30px;
}

.newsContent {
    margin: 50px 0px;
    text-align: left;
}

.newsContent p {
    font-size: 16px;
    font-weight: 500;
    color: #435568;
    line-height: 30px;
}

.newsContent h4 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #435568;
}

.share-news {
    display: flex;
    justify-content: space-between;
}

.quantity-tab .form-control {
    padding-left: 0px;
    padding-right: 0px;
    width: 50px;
    text-align: center;
}

.fscontainer {
    position: relative;
}

.fslabel {
    position: absolute;
    top: 0px;
    font-size: small;
    left: 30px;
    height: 25px;
    margin: -10px 0px;
    width: 110px;
    color: white;
    background-color: #6d2a5f !important;
    border: 3px solid #6d2a5f;
    text-align: center;
}

/* SharePopup css */
.shareblog .modal-header {
    border: 0px;
}

.shareblog .modal-dialog {
    max-width: 490px;
    width: 96%;
}

.share-socialMedia ul li span {
    font-size: 13px;
}

.share-socialMedia ul li a.whatsapp {
    background: #25d366;
}

.share-socialMedia ul li a.facebook {
    background: #3b5998;
}

.share-socialMedia ul li a.facebook svg {
    color: #fff;
}

.share-socialMedia ul li a.gmail {
    background: #888888;
}

.share-socialMedia ul li a.twitter {
    background: #1da1f2;
}

.share-socialMedia ul li a.twitter svg {
    color: #fff;
}

.share-socialMedia ul li a.Linkdin {
    background: #0077b5;
}

.share-socialMedia ul {
    list-style: none;
    display: flex;
    padding: 0px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.share-socialMedia ul li a {
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
    border-radius: 50%;
    margin: 0 auto 5px;
    font-size: 29px;
    color: #fff;
}

.share-socialMedia ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copyText {
    display: flex;
    justify-content: space-evenly;
    padding: 12px 12px;
    background: 1px rgb(243, 239, 239);
    border-radius: 10px;
}

.shareblog .modal-header .h4 {
    font-size: 20px;
}

.copyText input {
    width: 85%;
    border: 0px none;
    background: 1px rgb(243, 239, 239);
    outline: none;
}

.copyText button {
    padding: 3px 10px;
    border-radius: 18px;
    background: #6d2a5f;
    border: 1px solid #6d2a5f;
    box-shadow: none !important;
    transition: 1s;
    color: #fff;
}

.copyText button:hover {
    background: transparent;
    color: #201b4c;
}

.sharePost {
    padding: 3px 10px;
    text-align: center;
    border-radius: 18px;
    background: #201b4c;
    border: 1px solid #201b4c;
    box-shadow: none !important;
    transition: 1s;
    color: #fff;
}

.sharePost:hover {
    background: transparent;
    color: #201b4c;
}

.image-gallery-swipe {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.braintree-sheet {
    background: #fffef5;
}

.braintree-form__field-group .braintree-form__field {
    width: 100%;
}

.braintree-form__field-group label {
    width: 100%;
}

.braintree-large-button {
    background: transparent;
    color: #6d2a5f;
    font-size: 16px;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    border-radius: 55px;
    padding: 0px;
    font-weight: 600;
    border: 0px;
    transition: 1s;
    width: auto;
    text-decoration: underline;
}

.braintree-method,
.braintree-option {
    background-color: #fffef5 !important;
}

.braintree-methods--active:not(.braintree-methods--edit) .braintree-method--active {
    border-color: #6d2a5f;
}

.braintree-methods--active:not(.braintree-methods--edit) .braintree-method--active .braintree-method__check {
    background-color: #6d2a5f;
}

.braintree-method {
    width: 100%;
}

.braintree-large-button.braintree-toggle:hover {
    color: #6d2a5f !important;
    box-shadow: inset 240px 0px 0px #fffef5;
}

.braintree-large-button span {
    border: 0px;
}

.braintree-show-methods [data-braintree-id='methods-edit'] {
    right: 5px;
    cursor: pointer;
    background: #6d2a5f !important;
    border: 1px solid #6d2a5f !important;
    border-radius: 55px;
    transition: 1s;
    padding: 6px 13px;
    font-weight: 600;
    white-space: nowrap;
    line-height: 18px;
    color: #fff;
    text-decoration: none;
    top: -6px;
}

.braintree-upper-container:before {
    background-color: transparent;
}

.braintree-sheet--has-error .braintree-sheet__error {
    margin-top: 10px;
}

.image-gallery-slide-wrapper,
.image-gallery {
    overflow: hidden;
}

.card-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

.termsmodal .modal-body {
    padding-top: 0px;
}

.form-group {
    position: relative;
}

.form-group span.viewpass {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
}

.form-group span.viewpass {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
}

.creditcardform .show-card {
    position: absolute;
    top: -27px;
    left: 15px;
    color: #6d2a5f !important;
    font-weight: 500;
    background: #ffffff00 !important;
    border: 1px solid #6d2a5f !important;
    border-radius: 0px;
    padding: 6px 7px;
    font-size: 12px;
}

.creditcardform .show-card:hover {
    color: #6d2a5f !important;
    background: transparent !important;
}

.form-check input#custom-switch,
.list-group-item .form-check input.form-check-input {
    padding: 9px;
    position: relative;
    top: 2px;
}

.form-check label.form-check-label,
.list-group-item .form-check label.form-check-label {
    top: 2px;
}

.creditcardform .list-group-item {
    background: #fffef5;
}

button.delete-card {
    background: transparent !important;
    border: 0px !important;
    padding: 0px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

button.change-address {
    background: transparent !important;
    border: 0px !important;
    padding: 0px;
    color: #6d2a5f !important;
    font-weight: 400;
    margin: 2px 1px 3px 0px;
    text-decoration: underline;
    font-size: 14px;
    position: relative;
    left: 5px;
}

div:has(iframe[title="recaptcha challenge expires in two minutes"]):nth-child(2) {
    position: fixed !important;
    top: 20% !important;
}

div:has([role="tooltip"]) {
    z-index: 999;
}

.image-gallery-icon .image-gallery-svg {
    transform: scale(0.5) !important;
}

.image-gallery-icon:hover .image-gallery-svg {
    transform: scale(0.5) !important;
}

.imageTab {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 auto;
}

.imageTab .img-fluid {
    height: 770px;
    width: 100%;
    object-fit: cover;
}

.technology-mat-content .image-gallery-swipe {
    width: 100%;
}

.technology-mat-content .image-gallery-slides {
    width: 100%;
}

.technology-mat-content .image-gallery-slides iframe {
    width: 100%;
}

.addcard span {
    background: #ccc;
}

.addcard {
    margin: 15px 0px 15px;
}

.addcard .btn-primary {
    border-radius: 0px !important;
    padding: 7px 10px;
    height: initial;
    font-size: 12px;
}

.flex-box {
    margin-bottom: 20px;
    display: flex;
    border: 1px solid #ccc;
    position: relative;
}

span.card-flex {
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    border-right: 1px solid #ccc;
}

span.card-flex.cvv {
    right: 0px;
    left: initial;
    border-right: 0px;
    border-left: 1px solid #ccc;
}

input.form-control.card-number {
    padding-left: 65px;
    border: 0px;
}

.flex-box .form-control {
    border-radius: 0px;
    border: 0px;
    border-left: 1px solid #ccc;
    color: #435568;
    width: 100%;
    min-height: 45px;
    background-color: transparent;
    box-shadow: none !important;
}

.flex-box div {
    position: relative;
    width: calc(100% - 50px);
}

.flex-box div small {
    position: absolute;
    left: 3px;
}





a.dropdown-item {
    background: transparent !important;
}

.application {
    background-image: url(../src/img/videosBG.jpg);
    padding: 0px 0px;
    background-position: bottom;
    background-size: cover;
    min-height: 750px;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    background: #00000045;
}

.application .technology-mat-content h4 {
    text-transform: capitalize;
}

/* .application .technology-mat-content h2 {
    color: #000;
    font-size: 28px;
} */

.application .sliders {
    position: absolute;
    top: 50px;
    left: 50%;
    padding: 10px;
    width: 500px;
    background: #f5faff80;
    clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 100% 98%, 98% 100%, 2% 100%, 0% 98%, 0% 2%);
}

.application .sliders .border-box {
    position: relative;
    padding: 30px 40px 10px;
    z-index: 10;
    background: #fffef5;
    clip-path: polygon(0 2%, 2% 0, 98% 0, 100% 2%, 100% 98%, 98% 100%, 2% 100%, 0% 98%, 0% 2%);
}

.border-box .slider {
    margin-bottom: 20px;
}

.slider {
    margin-bottom: 10px;
}

.title {
    text-align: center;
    font-weight: 700;
    color: #000;
    font-size: 18px;
    line-height: 16px;
    text-transform: uppercase;

}

.attackangle .labels {
    justify-content: space-between;
    margin: 5px 18px 2px 7px;
}

.attackangle .labels .start-label {
    font-size: 14px;
}

.progresh-bg {
    height: 35px;
    border-radius: 55px;
    background: #fffef5;
    position: relative;
}


.labels {
    margin: 10px 0px 0px;
    height: 15px;
    position: relative;
    display: flex;
}

.labels .start-label,
.labels .end-label {
    font-weight: 700;
    font-size: 11px;
    text-align: center;
    position: relative;
    white-space: nowrap;
}

.labes-type .start-label.extra {
    text-align: center;
}

p.range-slider-counter {
    margin: 0px;
    font-weight: 700;
    font-size: 10px;
    color: #6d2a5f;
}

.thumb-dots {
    background-image: url(../src/img/arrowBG.png);
    background-size: 42px;
    width: 30px !important;
    background-repeat: no-repeat;
    outline: none;
    box-shadow: none;
    z-index: 10 !important;
    height: 30px !important;
    border-radius: 55%;
    border: 1px solid #fff;
    background-position: center;
    position: initial !important;
    top: -1px;
}

.progresh-bg.range-color .thumb-dots::before {
    content: "";
    position: absolute;
    background: #6d2a5f;
    height: 14px;
    right: 26px;
    border-radius: 55px 0px 0px 55px;
    top: 6px;
    width: 1000px;
}

.application .player-content {
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    height: 100%;
}

.application .landing-spot {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 30px;
    border-radius: 100px/15px;
    background-color: rgba(255, 255, 255, .6);
    border: 2px dashed #fff;
    position: absolute;
    left: 49%;
    top: 480px;
    margin-left: -135px;
}

.application .landing-spot.size-1 {
    width: 200px;
    height: 40px;
    border-radius: 100px/20px;
}

.application .landing-spot.size-2 {
    width: 120px;
    height: 24px;
    border-radius: 75px/15px;
    top: 375px;
}

.application .landing-spot.size-3 {
    width: 100px;
    height: 20px;
    border-radius: 50px/10px;
}

.application .landing-spot.distance-1 {
    top: 369px;
    margin-left: -135px;
}

.application .landing-spot.distance-2 {
    top: 363.5px;
    margin-left: -131px;
}

.application .landing-spot.distance-3 {
    top: 362px;
    margin-left: -127px;
}

.application .landing-spot.distance-4 {
    top: 359.5px;
    margin-left: -126px;
}

.application .landing-spot.distance-5 {
    top: 357px;
    margin-left: -125px;
}

.application .landing-spot.distance-6 {
    top: 354px;
    margin-left: -119px;
}

.application .landing-spot.distance-7 {
    top: 351px;
    margin-left: -117px;
}

.application .landing-spot.distance-8 {
    top: 349px;
    margin-left: -113px;
}

.application .landing-spot.distance-9 {
    top: 345px;
    margin-left: -109px;
}

.application .landing-spot.distance-10 {
    top: 341px;
    margin-left: -107px;
}

.application .landing-spot.distance-11 {
    top: 339px;
    margin-left: -105px;
}





.application .paths {
    left: 50%;
    position: absolute;
    bottom: 45px;
}

.application .paths .path {
    pointer-events: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 199px;
    height: 663px;
    bottom: 25px;
    margin-left: -200px;
    opacity: 0;
}

.application .paths .path.selected {
    opacity: 1;
}

.application .clubheads.selected {
    opacity: 1;
}

.application .clubheads .photo .clubhead.selected {
    opacity: 1;
}

.application .paths .high-200 {
    background-image: url(../src/img/high1.png);
}

.application .paths .high-210 {
    background-image: url(../src/img/high2.png);
}

.application .paths .high-220 {
    background-image: url(../src/img/high3.png);
}

.application .paths .high-230 {
    background-image: url(../src/img/high4.png);
}

.application .paths .high-240 {
    background-image: url(../src/img/high5.png);
}

.application .paths .high-250 {
    background-image: url(../src/img/high6.png);
}

.application .paths .high-260 {
    background-image: url(../src/img/high7.png);
}

.application .paths .high-270 {
    background-image: url(../src/img/high8.png);
}

.application .paths .high-280 {
    background-image: url(../src/img/high9.png);
}

.application .paths .high-290 {
    background-image: url(../src/img/high10.png);
}

.application .paths .high-300 {
    background-image: url(../src/img/high11.png);
}

.application .paths .mid-high-200 {
    background-image: url(../src/img/mid-high1.png);
}

.application .paths .mid-high-210 {
    background-image: url(../src/img/mid-high2.png);
}

.application .paths .mid-high-220 {
    background-image: url(../src/img/mid-high3.png);
}

.application .paths .mid-high-230 {
    background-image: url(../src/img/mid-high4.png);
}

.application .paths .mid-high-240 {
    background-image: url(../src/img/mid-high5.png);
}

.application .paths .mid-high-250 {
    background-image: url(../src/img/mid-high6.png);
}

.application .paths .mid-high-260 {
    background-image: url(../src/img/mid-high7.png);
}

.application .paths .mid-high-270 {
    background-image: url(../src/img/mid-high8.png);
}

.application .paths .mid-high-280 {
    background-image: url(../src/img/mid-high9.png);
}

.application .paths .mid-high-290 {
    background-image: url(../src/img/mid-high10.png);
}

.application .paths .mid-high-300 {
    background-image: url(../src/img/mid-high11.png);
}

.application .paths .medium-200 {
    background-image: url(../src/img/medium1.png);
}

.application .paths .medium-210 {
    background-image: url(../src/img/medium2.png);
}

.application .paths .medium-220 {
    background-image: url(../src/img/medium3.png);
}

.application .paths .medium-230 {
    background-image: url(../src/img/medium4.png);
}

.application .paths .medium-240 {
    background-image: url(../src/img/medium5.png);
}

.application .paths .medium-250 {
    background-image: url(../src/img/medium6.png);
}

.application .paths .medium-260 {
    background-image: url(../src/img/medium7.png);
}

.application .paths .medium-270 {
    background-image: url(../src/img/medium8.png);
}

.application .paths .medium-280 {
    background-image: url(../src/img/medium9.png);
}

.application .paths .medium-290 {
    background-image: url(../src/img/medium10.png);
}

.application .paths .medium-300 {
    background-image: url(../src/img/medium11.png);
}

.application .paths .low-mid-200 {
    background-image: url(../src/img/low-mid1.png);
}

.application .paths .low-mid-210 {
    background-image: url(../src/img/low-mid2.png);
}

.application .paths .low-mid-220 {
    background-image: url(../src/img/low-mid3.png);
}

.application .paths .low-mid-230 {
    background-image: url(../src/img/low-mid4.png);
}

.application .paths .low-mid-240 {
    background-image: url(../src/img/low-mid5.png);
}

.application .paths .low-mid-250 {
    background-image: url(../src/img/low-mid6.png);
}

.application .paths .low-mid-260 {
    background-image: url(../src/img/low-mid7.png);
}

.application .paths .low-mid-270 {
    background-image: url(../src/img/low-mid8.png);
}

.application .paths .low-mid-280 {
    background-image: url(../src/img/low-mid9.png);
}

.application .paths .low-mid-290 {
    background-image: url(../src/img/low-mid10.png);
}

.application .paths .low-mid-300 {
    background-image: url(../src/img/low-mid11.png);
}

.application .paths .low-200 {
    background-image: url(../src/img/path-low1.png);
}

.application .paths .low-210 {
    background-image: url(../src/img/path-low2.png);
}

.application .paths .low-220 {
    background-image: url(../src/img/path-low3.png);
}

.application .paths .low-230 {
    background-image: url(../src/img/path-low4.png);
}

.application .paths .low-240 {
    background-image: url(../src/img/path-low5.png);
}

.application .paths .low-250 {
    background-image: url(../src/img/path-low6.png);
}

.application .paths .low-260 {
    background-image: url(../src/img/path-low7.png);
}

.application .paths .low-270 {
    background-image: url(../src/img/path-low8.png);
}

.application .paths .low-280 {
    background-image: url(../src/img/path-low9.png);
}

.application .paths .low-290 {
    background-image: url(../src/img/path-low10.png);
}

.application .paths .low-300 {
    background-image: url(../src/img/path-low111.png);
}

/* 
.application .paths .low-mid-200 {
    background-image: url(../src/img/path-low-mid11.png);
}

.application .paths .low-mid-210 {
    background-image: url(../src/img/path-low-mid22.png);
}

.application .paths .low-mid-220 {
    background-image: url(../src/img/path-low-mid3.png);
}

.application .paths .low-mid-230 {
    background-image: url(../src/img/path-low-mid4.png);
}

.application .paths .low-mid-240 {
    background-image: url(../src/img/path-low-mid5.png);
}

.application .paths .low-mid-250 {
    background-image: url(../src/img/path-low-mid6.png);
}

.application .paths .low-mid-260 {
    background-image: url(../src/img/path-low-mid7.png);
}

.application .paths .low-mid-270 {
    background-image: url(../src/img/path-low-mid8.png);
}

.application .paths .low-mid-280 {
    background-image: url(../src/img/path-low-mid9.png);
}

.application .paths .low-mid-290 {
    background-image: url(../src/img/path-low-mid10.png);
}








.application .paths .low-200 {
    background-image: url(../src/img/path-low11.png);
}

.application .paths .low-210 {
    background-image: url(../src/img/path-low22.png);
}

.application .paths .low-220 {
    background-image: url(../src/img/path-low33.png);
}

.application .paths .low-230 {
    background-image: url(../src/img/path-low44.png);
}

.application .paths .low-240 {
    background-image: url(../src/img/path-low55.png);
}

.application .paths .low-250 {
    background-image: url(../src/img/path-low66.png);
}

.application .paths .low-260 {
    background-image: url(../src/img/path-low77.png);
}

.application .paths .low-270 {
    background-image: url(../src/img/path-low88.png);
}

.application .paths .low-280 {
    background-image: url(../src/img/path-low99.png);
}

.application .paths .low-290 {
    background-image: url(../src/img/path-low100.png);
}

 */










.player-image {
    pointer-events: none;
    position: absolute;
    left: 57%;
    background: transparent url(../src/img/playergolf.png) center center no-repeat;
    background-size: contain;
    width: 334px;
    height: 342px;
    bottom: 25px;
    margin-left: -637px;
}

.application .clubheads {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease;
}

.application .clubheads .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, .6);
    border: 2px dashed #fff;
    position: absolute;
    left: 50%;
    top: 460px;
    margin-left: -302px;
}

.application .clubheads .line {
    width: 2px;
    height: 80px;
    position: absolute;
    border-left: 2px dashed #fff;
    position: absolute;
    left: 50%;
    top: 360px;
    margin-left: -323px;
    transform: rotate(-15deg);
}

.application .clubheads .photo {
    width: 250px;
    height: 180px;
    background-color: rgba(255, 255, 255, .8);
    position: absolute;
    top: 4px;
    left: 4px;
}

.application .clubheads .border-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid rgba(255, 255, 255, .8);
    width: 266px;
    height: 196px;
    position: absolute;
    left: 48%;
    top: 263px;
    margin-left: -400px;
}

.application .clubheads .photo .clubhead {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .2s ease;
}

.application .clubheads .photo .clubhead.selected {
    opacity: 1;
}

.application .title {
    position: relative;
    z-index: 2;
    margin-bottom: 15px;
}

.application .clubheads .photo .title {
    text-align: center;
    font-weight: 700;
    padding-top: 15px;
    text-transform: uppercase;
}

.application .clubheads .photo .clubhead .image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.application .clubheads .photo #clubhead-2 .image {
    background: transparent url(../src/img/clubhead-2.webp) center center no-repeat;
    background-size: cover;
    width: 96px;
    height: 124px;
    margin: 0 auto;
}

.application .clubheads .photo #clubhead-3 .image {
    background: transparent url(../src/img/clubhead-3.webp) center center no-repeat;
    background-size: cover;
    width: 95px;
    height: 127px;
    margin: 0 auto;
}

.start-label.active {
    color: #6d2a5f;
    text-align: center;
}

.attackangle .start-label {
    width: auto;
}

.desired-shaft .start-label {
    white-space: nowrap;
    position: relative;
}

.launch-angle .start-label {
    white-space: nowrap;
    position: relative;
}

/* .nextrange .progresh-bg.range-color::after{
    height: 15.5px;
} */
/* .slider.attackangle .start-label::before {
    content: "";
    position: absolute;
    top: 34px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 1px;
    height: 16px;
    background: #fffef563;
    margin-top: 0px;
} */

.start-label.active::before {
    opacity: 0;
}

.tip-sub svg {
    top: -12px;
    position: relative;
    left: 2px;
    cursor: pointer;
    font-size: 14px;
}

.paymentoption.ironPaymentOption {
    align-items: start !important;
}

.start-label.extra {
    color: #6d2a5f;
    font-size: 14px;
    margin-top: 3px;
    text-align: center;
    transition: 0.5s;
    width: 100%;
}

.inner-label {
    position: absolute;
    width: 100%;
    z-index: 0;
    top: 9px;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    background: #fffef5;
    border-radius: 55px;
    height: 15px;
    border: 1px solid #6d2a5f;
}

.inner-label label.first {
    width: 1px;
    height: 14px;
    background: #00000063;
    margin-top: 0px;
}

.progresh-bg.range-color::before {
    content: "";
    position: absolute;
    width: 36px;
    height: 20px;
    background: #fffef5;
    z-index: 10;
    left: -15px;
    top: 6px;
}

.progresh-bg.range-color::after {
    content: "";
    position: absolute;
    left: 0px;
    width: 21px;
    height: 15px;
    background: #6d2a5f;
    top: 9px;
    z-index: 10;
    border-radius: 55px 0px 0px 55px;
    border: 1px solid #6d2a5f;
}


.iron-content {
    padding: 40px;
    background: #fffef5;
    margin: 0px 0px 30px;
    box-shadow: 0px 40px 50px rgb(0 0 0 / 5%);
}

.questions {
    font-size: 14px;
    font-weight: 600;
    color: #6d2a5f;
}

.iron-content h4 {
    margin: 4px 0px;
    font-size: 30px;
    font-weight: 700;
    color: #435568;
}

span.selectone {
    font-size: 13px;
    font-weight: 500;
    color: #435568;
}

.labes-type {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    flex-wrap: wrap;
}

.lab-outer {
    position: relative;
    margin: 12px 10px;
}

.lab-outer label {
    display: inline-block;
    padding: 10px 23px;
    background: transparent;
    border: 1px solid #435568;
    border-radius: 55px;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
}

.lab-outer input {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
}

.lab-outer input:checked+label {
    background: #6d2a5f;
    border-color: #6d2a5f;
    color: #fff;
}

.back-next .btn-primary {
    margin: 10px 10px;
    color: #fff;
    text-decoration: none;
}

.back-next {
    margin-top: 40px;
}

.facility-outer.irons-tabs {
    padding: 20px 0px;
    background-image: url(../src/img/result-bg.jpg);
}

.iron-content .progress {
    margin-bottom: 20px;
    height: 3px;
}

.iron-content .progress-bar {
    background: #6d2a5f;
}

.labes-type .sliders,
.changeMetirc {
    width: 610px;
    max-width: 100%;
}

.changeMetirc {
    margin: 0 auto;
    text-align: left;
}

.changeMetirc label {
    margin-left: 5px;
    position: relative;
    top: -2px;
    font-size: 13px;
    font-weight: 500;
    color: #435568;
    text-transform: none;
}

.kinetixx-slider-item {
    margin: 30px 0px 50px;
}

.kinetixx-slider-item .product-outer a {
    padding: 12px 10px;
}

.kinetixx-golf h4 {
    margin: -23px 0px 10px;
    color: #435564;
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    transition: 1s;
}

.kinetixx-slider-item .product-outer-main {
    width: 95%;
    margin: 0 auto;
}

.golf-Images {
    overflow: hidden;
}

.golf-Images img {
    transition: 0.5s;
    mix-blend-mode: multiply;
}

.product-outer:hover .golf-Images img,
.product-outer:hover h4 {
    transform: scale(1.09);
}

.pt-0 {
    padding-top: 0px !important;
}

.result-productimages img {
    height: 305px;
}

.facility-bg {
    padding: 20px;
    background-image: url(../src/img/status-shifted.jpg);
    border-radius: 5px;
    background-position: top;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

.facility-bg::before {
    content: "";
    background-color: #fffef5;
    position: absolute;
    opacity: 0.95;
    z-index: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.facility-bg .row {
    position: relative;
    z-index: 10;
}

.result-productimages {
    box-shadow: 0px 0px 11px #cccccc4d;
    border-radius: 5px;
    overflow: hidden;
}

.small .productdetails-right h1 a {
    font-size: 24px;
}

.small .productdetails-right h4 {
    font-size: 16px;
    text-align: left !important;
}

.small .paymentoption h5 {
    font-size: 20px;
    width: 100%;
}

.small .result-productimages img {
    height: 155px;
    background: #fff;
    object-fit: contain;
}

.small .paymentoption {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.small .paymentoption .addcart {
    margin-top: 15px;
    width: 100%;
}

.small .alltypes p {
    font-size: 14px;
}

.small .productdetails-right h1 a span.match {
    font-size: 11px;
    position: absolute;
    right: 6px;
    top: -5px;
}

.productdetails-right h1 a span.match {
    font-size: 18px;
    position: relative;
    top: -5px;
}

.other-match {
    text-align: center;
    margin-bottom: 30px;
    color: #6d2a5f;
}

.other-match h4 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 32px;
    margin-bottom: 50px;
}

/* section.Driver-selecter-outer.facility-outer.otherProducts {
    background-image: url(../src/img/result-bg.jpg);
    height: initial !important;
    position: relative;
    z-index: 0;
} */

.SelectProduct-Page {
    background-image: url(../src/img/result-bg.jpg);
    background-position: bottom;
    padding: 50px 0px 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.SelectProduct-Page::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #fff5f58f;
    z-index: 0;
}

.SelectProduct-Page .container {
    position: relative;
    z-index: 10;
}

.SelectProduct-Page>h1 {
    background: #6d2a5f;
    text-align: center;
    padding: 20px 0px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
}

.selector-tools {
    text-align: center;
    padding: 10px 0px;
    color: #435568;
}

.selector-tools h5 {
    font-size: 18px;
    font-weight: 700;
}

.selector-tools h5 span {
    font-style: italic;
}

.selector-tools h6 {
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
}

.selector-tools>p {
    width: 80%;
    margin: 0 auto;
    font-weight: 500;
}

.selector-tools-body {
    width: 60%;
    margin: 40px auto 40px;
    display: flex;
    justify-content: space-between;
}

.select-tool-info {
    text-align: left;
    width: 50%;
    padding-right: 15px;
}

.select-itemImage {
    width: 50%;
    text-align: right;
}

.select-itemImage img {
    width: 100%;
    max-width: calc(100% - 40px);
    min-height: 280px;
    object-fit: contain;
}

p.select-title {
    font-weight: 600;
    color: #435568;
}

.select-tool-info .alltypes {
    text-align: left;
    margin: 0px;
}

.selector-tools h1 {
    font-weight: 700;
    color: #6d2a5f;
    margin: 10px 0px;
}

.select-tool-info .alltypes p {
    color: #435568;
    font-size: 20px;
}

.select-tool-info .alltypes p.theme-color {
    color: #6d2a5f;
}

.price-cart {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.price-cart span.price {
    font-size: 30px;
    font-weight: 700;
    color: #6d2a5f;
    margin-bottom: 5px;
}

.price-cart button {
    width: 80%;
    margin: 0px 0px 8px;
}

.price-cart button.explore-btn,
.paymentoption .explore-btn {
    background: #435568 !important;
    border-color: #435568 !important;
}

.productborder {
    background: #fffef5;
    text-align: center;
    padding: 20px 0px;
    border-radius: 5px;
}

.match-product {
    position: absolute;
    right: 0px;
    background: #6d2a5f;
    padding: 10px 10px 10px 25px;
    border-radius: 55px 0px 0px 55px;
    color: #fff;
    font-weight: 600;
    top: 40%;
}

.small .alltypes {
    margin: 2px 0px 15px;
}

.price-cart button.explore-btn:hover,
.paymentoption .explore-btn:hover {
    color: #435568 !important;
}

.paymentoption .btn-primary {
    font-size: 14px;
    padding: 10px 15px;
    min-width: 169px;
}

.paymentoption .text-center {
    display: block;
    margin-top: 10px;
    width: 100%;
    text-align: left !important;
    padding-left: 35px;
}

.paymentoption .text-center a.Compare-link {
    text-decoration: underline;
    color: #6d2a5f;
    font-weight: 600;
}

.back-next a {
    color: #435568;
    font-weight: 600;
    font-size: 18px;
    margin: 0px 15px;
    text-transform: capitalize;
}

.back-next a:hover {
    color: #6d2a5f;
}

a.text-gray {
    color: #435568;
    font-weight: 600;
}

.modal-title.h4 svg {
    position: relative;
    top: -2px;
}

.progressBar .progress-bar {
    /* padding: 40px; */
    background: #6d2a5f;
    transition: 0.5s;
    /* margin: 0px 0px 30px; */
    /* box-shadow: 0px 40px 50px rgb(0 0 0 / 5%); */
}

.progressBar .progress {
    width: 100%;
    text-align: center;
}

.selector-tools .progressBar {
    width: 70%;
    margin: 40px auto;
}

.small .progressBar .progress {
    height: 5px;
    margin-bottom: 10px;
}

/* .kinetixx-slider-item .react-multiple-carousel__arrow--left,
.kinetixx-slider-item .react-multiple-carousel__arrow--right{
    display: none;
} */
.labes-type .slider {
    margin-bottom: 0px;
}

.selector-tools .progressBar .progress,
.small .progressBar .progress {
    overflow: initial;
    border-radius: 55px;
}

.selector-tools .progressBar .progress .progress-bar,
.small .progressBar .progress .progress-bar {
    border-radius: 55px;
    overflow: initial;
    position: relative;
}

.selector-tools .progressBar .progress .progress-bar::before,
.small .progressBar .progress .progress-bar::before {
    content: "";
    position: absolute;
    right: 0px;
    width: 25px;
    height: 25px;
    background: #6d2a5f;
    border-radius: 50%;
    border: 1px solid #e9ecef;
}

.small .progressBar .progress .progress-bar::before {
    width: 15px;
    height: 15px;
}

.small .productdetails-right h1 {
    line-height: 25px;
}

.notify-modal-footer.modal-footer {
    padding: 12px 14px 0px 0px;
    width: calc(100% + 33px);
    margin-left: -17px;
    margin-top: 30px;
}

.technology-mat-content p {
    white-space: pre-line;
    word-wrap: break-word;
}

.selector-tools .progressBar .progress {
    border: 1px solid #6d2a5f;
}

.progressBar .progress {
    border: 1px solid #6d2a5f;
}

.custom_check svg {
    position: relative;
    z-index: 100;
}

label.swingspeed {
    font-size: 11px;
    padding-left: 4px;
    position: relative;
    top: -3px;
}

.question-list p {
    font-weight: 500;
}

.question-list input {
    margin-left: 13px;
}

.graphpanel {
    position: relative;
    padding-top: 20px;
}

.graphpanel::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff9;
}

.strecher {
    width: 830px;
    height: 730px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    border-radius: 10px;
    /* background: rgb(225, 194, 220);
    background: linear-gradient(16deg,
            rgba(169, 85, 152, 1) 50%,
            rgba(114, 57, 103, 1) 75%,
            rgba(60, 29, 53, 1) 100%); */
}

.graphOuter {
    width: 830px;
    margin: 1px 0 50px auto;
    position: relative;
    z-index: 0;
}

.divaider-1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    overflow: hidden;
}

.divaider-2 {
    border: 1px solid #8d477f;
    height: initial;
    display: flex;
    transition: 0.5s;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    width: calc(100% / 5);
    height: 146px;
    min-height: 146px;
    border-radius: 10px;
    overflow: auto;
    position: relative;
    background: #fffef5;
    transition: 1s;
}

.V-label {
    display: flex;
    justify-content: space-between;
}

.V-label label {
    font-size: 14px;
    margin: 5px 0px;
    width: calc(100% / 5 - 0px);
    text-align: center;
}

.H-label {
    position: absolute;
    bottom: 0px;
    top: 0px;
    display: flex;
    height: 730px;
    justify-content: space-between;
    flex-direction: column;
    left: -96px;
    text-align: right;
}

p.cart-quantity .quantity-tab .btn.update-btn {
    margin-left: 10px;
    border-radius: 55px;
}

.H-label label {
    font-size: 14px;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: end;
}

label.label-first {
    opacity: 0;
    display: none;
}

.divaider-2 span {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #6d2a5f;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 1s;
    padding: 6px;
    flex-wrap: wrap;
    font-weight: 700;
}

.divaider-2 span div {
    width: 100%;
    font-weight: normal;
}

.launch-heading {
    position: absolute;
    transform: rotate(-90deg);
    top: 0px;
    bottom: 0px;
    text-transform: uppercase;
    z-index: 100;
    left: 100px;
}

.application.stepone .player-content {
    top: -42px;
}

.launch-heading h3 {
    font-weight: 600;
    font-size: 23px;
    color: #6d2a5f;
}

.spin-heading h3 {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 23px;
    color: #6d2a5f;
}

/* .divaider-2 span:hover {
    background: #fffef5;
    color: #6d2a5f;
    border-radius: 5px;
}

.divaider-2 span:hover small {
    background: #6d2a5f;
} */

.kinetixx-title {
    position: absolute;
    top: -60px;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.application .title .slider-circle {
    font-size: 16px;
    position: relative;
    left: 3px;
    top: -10px;
    cursor: pointer;
}

.slider.desired-shaft.current-shaft .start-label.extra {
    text-transform: capitalize;
}

.kinetixx-title span {
    font-weight: 700;
    color: #435568;
    position: relative;
    transition: 0.5s;
    font-size: 18px;
    cursor: pointer;
}

.kinetixx-title span.active,
.kinetixx-title span:hover {
    transform: scale(1.2);
    color: #6d2a5f;
}

p.cart-quantity {
    display: flex;
    align-items: center;
}

p.cart-quantity .quantity-tab {
    margin: 0px 10px;
}

p.cart-quantity .quantity-tab .btn {
    padding: 1px 5px;
}

td.trackStatus {
    min-width: 150px;
    width: 155px;
    vertical-align: middle;
}

td.trackPackage {
    min-width: 150px;
    width: 150px;
    vertical-align: middle;
}

td.trackPackage .btn-primary {
    padding: 5px 15px;
    font-size: 12px;
}

.metricChange {
    position: relative;
}

.metricChange .changeMetirc {
    position: absolute;
    right: 15px;
    top: 25px;
    width: auto;
}

.metricChange .changeMetirc a {
    font-size: 12px;
    font-weight: 600;
    color: #6d2a5f;
}

.iron-content .metricChange .changeMetirc {
    top: 78px;
    right: 160px;
}

.divaider-2 span.result-count {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    font-size: 11px;
    background: #fff5f5;
    color: #6d2a5f;
    border: 1px solid #6d2a5f;
    display: none;
}

.divaider-2 span.result-show {
    position: absolute;
    width: 100%;
    height: auto;
    padding: 5px 0px;
    right: 30px;
    top: 20px;
    font-size: 14px;
    /* background: #fff5f5; */
    color: #6d2a5f;
    border: 1px solid #6d2a5f;
}

.player-content-outer {
    width: 100%;
    position: relative;
    height: 750px;
}

.text-center.submit-outer {
    margin-top: -10px;
}

.text-center.submit-outer.model-footer {
    margin-top: -10px;
    margin-bottom: -8px;
}

.labes-type .start-label.extra {
    text-align: left;
}

.labes-type .start-label.extra+.start-label.extra {
    text-align: center;
}

.labes-type .start-label.extra+.start-label.extra+.start-label.extra {
    text-align: right;
}

.alltypes p span.selectorColor {
    margin-left: 7px;
    display: inline;
    font-weight: 400;
    font-size: 13px;
}

.product-table-main {
    padding: 15px;
}

.product-table-main h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0 10px;
}

table.table.table-striped-rows.table-bordered tr th {
    font-size: 12px;
    margin: 0px;
    font-weight: 500;
    line-height: 18px;
    font-weight: 700;
}

table.table.table-striped-rows.table-bordered tr td {
    font-size: 12px;
    margin: 0px;
    font-weight: 500;
    line-height: 18px;
}

.btn-outline-primary {
    border: 1px solid #6d2a5f !important;
    color: #6d2a5f;
    font-weight: 600;
    border-radius: 55px;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    background: #6d2a5f !important;
    color: #fffef5;
}

.btn-secondary {
    background: #435568 !important;
    border-color: #435568 !important;
    padding-left: 25px;
    padding-right: 25px;
    transition: 1s;
    font-weight: 600;
}

.btn-secondary:hover {
    box-shadow: inset 240px 0px 1px #fffdf5;
    color: #435568 !important;
}

.coupancodeouter button.w-100.btn.btn-primary {
    transition: 2s;
}

.coupancodeouter button.w-100.btn.btn-primary:hover {
    box-shadow: inset 600px 0px 1px #fffdf5;
}

.compare-btn-outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.compare-btn-outer .btn {
    margin-bottom: 10px;
    min-width: 170px !important;
}

.lab-outer label {
    text-transform: initial;
}

.paymentoption .text-center a.Compare-link span {
    text-transform: lowercase;
    display: inline-block;
    text-decoration: underline;
}

.paymentoption .text-center a.Compare-link span:first-letter {
    text-transform: capitalize;
}

.cart-bottom-buttons {
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
}

.media-content h4 a {
    word-wrap: break-word;
}

.mainDriver-selector.technology-mat-content.text-center {
    position: relative;
    z-index: 1;
    padding: 0px 0px;
    color: #435569;
}

/* .mainDriver-selector.technology-mat-content.text-center h4 {
    color: #000;
    font-size: 28px;
    margin: 10px 0px;
} */

/* .mainDriver-selector.technology-mat-content.text-center h2 {
    font-size: 40px;
    color: #000;
    font-family: sans-serif;
    font-size: 40px;
    font-style: inherit;
    font-weight: 900;
    letter-spacing: -2px;
    margin: 0;
    text-transform: uppercase !important;
} */

.quantity-tab span.product-quantity {
    border: 2px solid #000;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px 2px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
}

input#quantity {
    width: 103px;
    border: 2px solid #333332;
    text-align: center;
    outline: none !important;
    background: #fffef5;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    transform: translateX(-30%);
    opacity: 0;
    transition: opacity 0.3s;
    width: 200px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip[disabled] .tooltiptext {
    visibility: visible;
    opacity: 1;
}


.range-outer {
    position: relative;
    padding: 8px 15px;
    overflow: hidden;
}

/* .range-outer::before {
    content: "";
    top: 5px;
    bottom: 6px;
    position: absolute;
    width: 14px;
    background: #fffef5;
    left: -10px;
    z-index: 100;
    border-radius: 0px;
}

.range-outer::after {
    content: "";
    position: absolute;
    left: 0px;
    width: 5px;
    height: 16px;
    background: #6d2a5f;
    z-index: 10000;
    top: 8px;
    border-radius: 55px 0px 0px 55px;
} */
.product-chart-cart-list {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 15px;
    align-items: start;
}

.product-chart-cart-list .card-img-top {
    width: 100px;
    border: 1px solid rgb(204 204 204 / 41%);
    border-radius: 5px;
}

.product-chart-cart-list .card-body {
    padding: 0px 0 0 15px;
}

.product-chart-cart-list .card-body .h5 {
    font-size: 22px;
    font-weight: 700;
    color: #435568;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    margin-bottom: 0px;
}

.product-chart-cart-list .productdetails-right p {
    font-size: 15px;
    margin-bottom: 0px;
}

.product-chart-cart-list .productdetails-right p span {
    font-weight: 600;
    margin-right: 10px;
    display: inline-block;
}

section.Driver-selecter-outer.facility-outer.otherProducts .container {
    position: relative;
}

.changeMetirc .custom_check {
    font-size: 10px;
    line-height: 17px;
    padding-left: 22px;
}

.changeMetirc .custom_check .check_indicator {
    width: 15px;
    height: 15px;
}

.changeMetirc .custom_check input:checked+.check_indicator:before {
    width: 4px;
    height: 8px;
    left: 5px;
    top: 1px;
}

.border-box .slider {
    position: relative;
}

.tooltipSelector {
    position: absolute;
    font-size: 9px;
    background: #fffef5;
    box-shadow: 0px 1px 6px #e5e5e5;
    border: 0.3px solid black;
    z-index: 100;
    padding: 5px;
    text-align: center;
    top: 95px;
    border-radius: 8px;
    width: 97%;
    bottom: initial;
}

.label-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -5px;
}

span.label-item-inner {
    font-weight: 400;
    font-size: 14px;
}

.newsletter {
    background-image: url(../src/img/white-BG.jpg);
    padding: 100px 0px;
    background-size: 100%;
    border-top: 1px solid #ccc;
}

.newsImg img {
    width: 370px;
    border-radius: 40px;
    position: relative;
    left: 110px;
}

.newsletter-main {
    display: flex;
    align-items: center;
}

.newsletter-right {
    background: #435569;
    padding: 100px 50px 100px 150px;
    border-radius: 40px;
    color: #fff;
    margin: 0px;
}

.newsletter-right h2,
.newsletter-right p {
    color: #fffef5;
}

.newsletter-right p.message {
    font-size: 12px;
    line-height: initial;
    width: 70%;
}

.emailnews.form-group {
    position: relative;
    display: flex;
    width: 70%;
    margin: 20px 0px;
}

.emailnews.form-group .form-control {
    border: 0px !important;
    padding-left: 27px;
    background: #fffef5;
    border-radius: 55px;
    padding-right: 130px;
}

.emailnews.form-group .btn-primary {
    color: #fff;
    position: absolute;
    right: -4px;
    top: 0px;
    bottom: 0px;
    background: #435568 !important;
    border: 1px solid #435568 !important;
}

.newsletter-right p {
    font-size: 15px;
    line-height: 24px;
    width: 90%;
}

.stackable-carousel {
    padding: 0px 15px;
}

.react-multi-carousel-list.thankyou-slider {
    justify-content: center;
}

.thankyou-carousel-header {
    background: transparent;
    border: 0px;
    color: #6d2a5f;
    font-weight: bold;
    font-size: 31px;
    padding: 0px;
    margin: 0px 0 16px;
    text-transform: uppercase;
}

.newsletter .productTopBar {
    border-radius: 10px;
}

.loginMenu.enter-profile::after {
    display: none;
}

.sliderPart.advancements .slider-content h4 {
    color: #435568;
}

.product-outer a h3 img {
    height: 42px;
}



.social-icon {
    display: flex;
    align-items: center;
}

.social-icon .emailnews.form-group {
    margin: 0px;
    width: 331px;
}

.social-icon .emailnews.form-group .form-control {
    background: #fffef5;
    border: 1px solid #435568 !important;
    min-height: 36px;
}

.emailnews.form-group .btn-primary:hover {
    color: #435568 !important;
}

section.sliderPart.advancements .imageTab .img-fluid {
    height: 600px;
}

.form-group.sortBy {
    width: 240px;
}

.search-item svg {
    position: absolute;
    right: 17px;
    top: 14px;
    font-size: 20px;
}

.facility-outer .d-flex {
    justify-content: center;
}

.technology-tabs .d-flex {
    justify-content: center;
}

.price-cart button:hover {
    box-shadow: inset 330px 0px 0px #fffef5 !important;
}

.productdetails-right label svg {
    font-size: 15px;
    position: relative;
    top: -5px;
    left: 5px;
    cursor: pointer;
}

.divaider-2.one-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.divaider-2.one-center span {
    text-align: center;
    display: initial;
}

.divaider-2.one-center span div {
    text-align: center;
}

.divaider-2:hover {
    background: #6d2a5f;
    border-color: #6d2a5f99;
}

.divaider-2:hover span {
    color: #fffef5;
}

.selectproductcolor .form-group label span {
    font-weight: 400;
}

.detailTBOuter table.w-100.table.table-bordered thead tr th {
    background: #e7e5df9c;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    color: #435568;
    font-size: 15px;
}

.detailTBOuter table.w-100.table.table-bordered tbody tr td {
    text-align: center;
    font-size: 15px;
}

.otp-mode-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.otp-mode-option {
    margin-bottom: 20px;
}

.otp-mode-options label input {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.otp-mode-options label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divaider-2.one-center {
    background: #e7e5df;
    border-color: #8d477f;
    position: relative;
    pointer-events: none;
}

.divaider-2.one-center::before {
    content: "";
    position: absolute;
    background-image: url(../src/img/logo.svg);
    width: 70%;
    height: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100px;
    transition: 1s;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    opacity: 0.2;
}

.divaider-2.one-center:hover::before {
    transform: scale(1.3);
}

.divaider-2:hover span {
    font-weight: 800;
}

.divaider-2:hover span div {
    font-weight: 600;
}

.cart-bottom-buttons button.btn.btn-secondary {
    position: relative;
    top: -122px;
}

.checkoutOuter {
    margin-bottom: 122px;
}

.graph-anchor span {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 1s;
    display: inline-block;
}

.graph-anchor span:last-child {
    margin: 0px;
}

.technology-mat-content h4.graph-anchor {
    color: #435568;
}

.technology-mat-content h4 span {
    text-decoration: underline;
}

.graph-anchor span.active,
.graph-anchor span:hover {
    color: #6d2a5f;
    font-size: 30px;
}

.graph-anchor span:hover {
    transform: scale(1.04);
}

/* .To-factor .modal-dialog {max-width: 417px;} */

.To-factor .logoOuter {
    margin: 20px 0px 30px;
}

.graphpanel .sidebar-outer {
    width: 90%;
    position: relative;
    top: -20px;
}

.product-outer a.kinetixx-golf button {
    background: transparent !important;
    color: #6d2a5f;
    transition: 1s;
}

.product-outer:hover button {
    box-shadow: inset 200px 0px 1px #6d2a5f !important;
    color: #fffef5 !important;
}

.form-control.choose-file {
    min-height: initial;
}

label.uploadimg {
    text-transform: capitalize;
}

.show-mobile {
    display: none;
}

small.invalid-feedback-text.extra-error.form-text {
    position: initial;
    white-space: pre-line;
}

.custom-model-error {
    background: #43556830;
    border: 1px solid #435568;
    color: #435568;
}

.kinetixx-product .mobile-kinetixx-product {
    display: none;
}

ul.infolistdetails li+li {
    color: #6d2a5f;
    /* cursor: pointer; */
}

/* ul.infolistdetails li+li:hover {
    text-decoration: underline;
} */

.accountseprate a svg,
.accountseprate a h4 {
    transition: 1s;
}

.accountseprate a:hover svg,
.accountseprate a:hover h4 {
    color: #6d2a5f;
    transform: scale(1.1);
}

.accountInnner h2 span {
    color: #435568;
}

.alert-dismissible {
    border: 0px;
    border-left: 5px solid;
    max-width: 570px;
    margin: 0 auto 25px;
}

.alert-dismissible p {
    margin: 0px;
    position: relative;
    padding-left: 30px;
}

.alert-dismissible .alert-heading.h4 {
    margin: 0px;
}

.alert-dismissible p svg {
    font-size: 25px;
    margin-right: 7px;
    position: absolute;
    left: -4px;
    top: 1px;
}

.modalClass {
    max-width: 100%;
}

.preview-img {
    height: 70px !important;
    width: 70px;
    object-fit: contain;
}

.loginOuter ul.react-multi-carousel-dot-list {
    bottom: 10px;
}

.loginOuter .react-multi-carousel-list {
    padding-bottom: 60px;
}

.loginOuter ul.react-multi-carousel-dot-list .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: #6d2a5f;
    border-color: #6d2a5f;
}

.radio-driver.form-check label {
    position: relative;
    left: -1px;
    top: 0px;
}

.start-over.mt-4.row svg {
    font-size: 23px;
}

.resource-download {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.resource-list {
    color: #6d2a5f;
    font-size: 30px;
}

.image-gallery-slide {
    top: initial !important;
}

.custom_carousel_image {
    width: 100%;
}

.custom_carousel_image div {
    width: 100%;
}

.custom_carousel_image>div>img {
    max-height: 290px;
    object-fit: cover;
    object-position: center;
    height: 300px;
    width: 100% !important;
}

.custom_carousel_image>div>img+div>img {
    max-height: initial;
    height: initial;
    border: 5px solid #000;
}

/* Footer css start */
.boxtype {
    display: flex;
    justify-content: space-between;
}

.headingtab h4 {
    font-weight: 600;
    font-size: 20px;
    color: #435568;
}

.headingtab ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.headingtab ul a {
    color: #435568;
    text-decoration: none;
    font-size: 16px;
    line-height: 30px;
    transition: 1s;
}

.headingtab ul a:hover {
    text-decoration: underline;
    color: #6d2a5f;
}

.tablebox table {
    width: 100% !important;
}

.tablebox table thead tr th,
.tablebox table tbody tr td {
    padding: 5px;
}

.tablebox table {
    border: 1px solid #ccc;
}

.tablebox table thead tr th:first-child,
.tablebox table tbody tr td:first-child {
    border-right: 1px solid #ccc;
}

.tablebox table tbody tr:nth-child(odd) td {
    background-color: #0000000d;
}

.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f1f1f1;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 1000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.cookie-content {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cookie-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cookie-buttons button {
    padding: 8px 20px;
    margin: 5px 0;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #6d2a5f;
    color: white;
    font-size: 16px !important;
    height: 40px !important;
    width: 100px !important;
    position: static !important;
}

.cookie-buttons button:hover {
    background-color: #0056b3;
}
.custom_carousel_image div div {
    display: none;
}
.technology-mat-content h2+h4{
margin-bottom: 30px;
}

.technology-mat-content hr{
    display:none;
}
.video-headings h3{
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .cookie-buttons {
        flex-direction: row;
        /* Change direction back to row for smaller screens */
        justify-content: center;
        /* Center items horizontally */
    }

    .cookie-buttons button {
        margin: 0 10px;
        /* Add horizontal margin between buttons */
    }
}

@media(max-width: 576px){
    
    .reviewouter{
        justify-content: space-between;
    }
    .custom_carousel .slide, .video-wrapper, .preview-images {
        position: initial !important;
        height: initial;
    }
    .image-wrapper, .video-wrapper{
        margin-top: 0px !important;
    }
    .custom_carousel{
        margin: 0px !important;
    }
    .paymentoption h5, .quantity-tab, .paymentoption .addcart {
        margin-bottom: 10px !important;
    }
    
    .custom_carousel{
        display: inline;
    }
    .InnerPage img, img.Innerbanner{
        min-height: 200px;
    }
   
}